import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Framework7React from 'framework7-react';
import Framework7Keypad from 'framework7-plugin-keypad';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'framework7/css/framework7.bundle.min.css';
import 'framework7-plugin-keypad/dist/framework7.keypad.min.css';
import 'material-design-icons/iconfont/material-icons.css';
import Config from "./Config";
import welcomescreen from "f7-welcomescreen";

Framework7.use(Framework7React);
Framework7.use(Framework7Keypad);
Framework7.use(welcomescreen);

ReactDOM.render(<App/>, document.getElementById('app'));

const conf = new Config();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const check = () => {

	if (!('serviceWorker' in navigator) && !conf.debug) {
		throw new Error('No Service Worker support!')
	}
	if (!('PushManager' in window) && !conf.debug) {
		throw new Error('No Push API Support!')
	}
}

const registerServiceWorker = async () => {
	serviceWorkerRegistration.register();
	return true;
}
const requestNotificationPermission = async () => {
	const permission = await window.Notification.requestPermission();
	// value of permission can be 'granted', 'default', 'denied'
	// granted: user has accepted the request
	// default: user has dismissed the notification permission popup by clicking on x
	// denied: user has denied the request.
	if (permission !== 'granted') {
		throw new Error('Permission not granted for Notification');
	}


	if (navigator.storage && navigator.storage.persist) {
		const isPersisted = await navigator.storage.persist();
		console.log(`Persisted storage granted: ${isPersisted}`);
	}


	return true;
}
const main = async () => {
	check();
	if(!conf.debug) {
		const swRegistration = await registerServiceWorker();
		const permission = await requestNotificationPermission();
		return swRegistration * permission;
	} else {
		return true;
	}

	/*navigator.serviceWorker.ready.then(registration => {
		console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAaa");
		try {
			registration.periodicSync.register('get-latest-news', {
				// Minimum interval at which the sync may fire.
				minInterval: 20 * 60 * 1000,
			});
		} catch (e) {
			console.log(e);
		}
	});

	navigator.serviceWorker.ready.then(registration => {
		registration.periodicSync.getTags().then(tags => {
			console.log("FFFFFFFFFFFFFFFFFFFFFF", tags);
		});
	});*/


}

main().then(r => {
	if (r) {
		console.log("App Started");
	}
});
