import axios from "axios";
import qs from "qs";

import sha256 from 'crypto-js/sha256';

import Config from "../Config";

export default function IWAjax(params) {
    const config = new Config();
    if(!params.data) params.data = [];

    const app = params.app;
    params.data.username_palmare = localStorage.getItem("username_palmare");
    params.data.username = "tech";
    if(typeof params.showPreloader === "undefined") params.showPreloader = true;
    const ws = function(){
        if(params.showPreloader) app.preloader.show();
        //console.log(params.route);
        console.log(JSON.stringify(params.data));

        if (params.route === "writeEntities"){
            params.data = syncParser(params.data);
            console.log("POST");
            console.log(JSON.stringify(params.data));
        }
        return axios({
            method: 'POST',
            headers: {'Authorization': localStorage.getItem("token_type") + " " + localStorage.getItem("access_token")},
            data: qs.stringify(params.data),
            url: config.api_url + params.route
        }).then(function (res){
            if(params.showPreloader) app.preloader.hide();
            if(!res.data) {
                app.dialog.alert("Errore di comunicazione con il server", "IrideWeb", function(){
                    //app.views.main.router.navigate("/home/")
                });
                return false;
            }
            if(res.data.error === "Invalid Username"){
                localStorage.clear();
                app.dialog.alert("Utente loggato non valido. Effettuare l'accesso al sistema", "IrideWeb", function(){ app.router.navigate("/login/")});
                return false;
            }
            if(typeof params.success === "function")
                params.success(typeof res.data === "undefined" ? {error: "Errore di comunicazione con il server"} : res.data);

            return true;
        });
    };

    if(!localStorage.getItem("access_token"))
        getToken(ws, app, params);
    else {
        ws().catch(function (error) {
            if(error.response && error.response.status === 401)
                getToken(ws, app, params);
            else{
                if(typeof params.error === "function") {
                    console.log("1", error.message);
                    console.log(params.error);
                    return params.error(error.response ? error.response.data.message : error.message);
                }
            }
            if(params.showPreloader) app.preloader.hide();
        });
    }
}

function getToken(ws, app, params){
    const config = new Config();

    axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
            client_id: config.codicecliente,
            grant_type: "client_credentials",
            client_secret: config.client_secret
        }),
        url: config.iride_url + "/api/webapps/token"
    }).then(res => res.data.data).then(function(res) {
        localStorage.setItem("token_type", res.token_type);
        localStorage.setItem("access_token", res.access_token);

        if(typeof ws === "function"){
            ws().catch(function (error) {
                if(typeof params.error === "function") {
                    return params.error(error.response.data.message);
                }
            });
        }
    }).catch(function (error){
        if(navigator.onLine && error.response) {
            localStorage.removeItem("user");
            localStorage.removeItem("username");
            localStorage.removeItem("username_palmare");
            localStorage.removeItem("camion");
            app.views.main.router.navigate("/login/");
            if (params.showPreloader) app.preloader.hide();
        } else {
            console.log("OFFLINE");
            if(typeof params.error === "function"){
                params.error(error);
            }
        }
    });
}

function syncParser(data) {
    if(data.docs) {
        const b64 = btoa(JSON.stringify(data.docs));
        const hashDigest = sha256(b64).toString();

        data.docs = b64;
        data.hash = hashDigest;
        return data;
    }

    return data;
}