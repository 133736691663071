import React from 'react';
import {
	Page,
	List,
	ListButton,
	NavLeft,
	NavRight,
	Link,
	Icon,
	ListGroup,
	ListItem, NavTitle, Navbar
} from 'framework7-react';
import {IWPanel, SelectAutomezzi} from "./IWComponents";
import Mezzi from "./entities/Mezzi";
import iride_logo from "./assets/iride_logo.png";

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			muletto_buttons_scarico: [
				{key: "o2s", title: "Bins Olma => Socio", url: "/magazzino/movimentazione/"},
				{key: "o2m", title: "Bins Olma => Camion", url: "/magazzino/movimentazione/"},
			],

			muletto_buttons_carico: [
				{key: "s2o", title: "Bins Socio => Olma", url: "/magazzino/movimentazione/"},
				{key: "m2o", title: "Bins Camion => Olma", url: "/magazzino/movimentazione/"},
			],

			camion_buttons_scarico: [
				{key: "c2s", title: "Bins Camion => Socio", url: "/magazzino/movimentazione/"},
			],

			camion_buttons_carico: [
				{key: "s2c", title: "Bins Socio => Camion - (Emissione Bolletta)", url: "/magazzino/bolletta/"},
				{key: "s2m", title: "Bins Socio => Camion - (Vuoti)", url: "/magazzino/movimentazione/"}
			],

			automezzi: [],
			mezzo: {},
			user: JSON.parse(localStorage.getItem("user")),
			camion: localStorage.getItem("camion") || "",
			is_trasportatore: false
		};

		this.automezzoChanged = this.automezzoChanged.bind(this);
		this.selectMezziOpen = this.selectMezziOpen.bind(this);
	}

	UNSAFE_componentWillMount() {
		const self = this;
		const app = self.$f7;
		if (app.panel.get("left"))
			app.panel.get("left").destroy();
	}

	async componentDidMount() {
		const self = this;
		const app = self.$f7;

		app.methods.sendCoordinates();

		const setup_complete = localStorage.getItem("app_setup_completed");
		if(!parseFloat(setup_complete)){
			self.$f7.methods.bgSync(0, () => {
				localStorage.setItem("app_setup_completed", "1");
				self.$f7.welcomescreen.close();
			})
		} else
			self.$f7.welcomescreen.close();

		self.logout = self.logout.bind(this);
		app.methods.hideSoftKeyboard();

		window.appLogout = function () {
			self.logout(true);
		};

		const is_trasportatore = (self.state.user.is_trasportatore === "1" || self.state.user.username === "tech");

		self.setState({is_trasportatore: is_trasportatore});

		if (self.state.camion !== "") {
			const m = await new Mezzi();
			m.getById(self.state.camion).then(r => {
				self.setState({"mezzo": r});
			});
		}

		//console.log(self.state.camion);
		//console.log(self.state.user.is_trasportatore);

		if (is_trasportatore && !self.state.camion) {
			setTimeout(() => {
				self.selectMezziOpen();
			}, 500);
		}

		/*
		const repl = await new Movimentazioni().replicate2();
		console.log(repl);
		*/

	}

	selectMezziOpen() {
		const self = this;
		const app = self.$f7;

		let smartSelect = app.smartSelect.get('.smart-select');
		smartSelect.open();
	}

	logout(close) {

		const self = this;
		const app = self.$f7;
		app.dialog.confirm("Confermare?", "Logout", function () {
			localStorage.removeItem("username");
			localStorage.removeItem("username_palmare");
			localStorage.removeItem("user");
			localStorage.removeItem("camion");
			app.panel.get("left").destroy();
			if (close && app.methods.isAndroidWebView())
				window.location.href = "http://killme";
			else
				self.$f7router.navigate("/login/");
		});
	}

	async automezzoChanged() {
		const self = this;
		const app = self.$f7;
		let smartSelect = app.smartSelect.get('.smart-select');
		const idmezzo = smartSelect.getValue();
		const m = await new Mezzi();
		m.getById(idmezzo).then(r => {
			self.setState({camion: idmezzo, mezzo: r});
			localStorage.setItem("camion", idmezzo.toString());
		});
	}

	render() {
		const self = this;
		const is_tech = self.state.user.username === "tech";
		const {is_trasportatore} = self.state;
		//const {buttons_scarico, buttons_carico} = self.state;

		let buttons_scarico = is_trasportatore ? self.state.camion_buttons_scarico : self.state.muletto_buttons_scarico;
		let buttons_carico = is_trasportatore ? self.state.camion_buttons_carico : self.state.muletto_buttons_carico;

		let buttons_sc = [];

		if (is_tech) {
			buttons_sc.push([self.state.muletto_buttons_scarico, self.state.muletto_buttons_carico, "Muletto"])
			buttons_sc.push([self.state.camion_buttons_scarico, self.state.camion_buttons_carico, "Camion"])
		} else {
			const label = is_trasportatore ? "Camion" : "Muletto";
			buttons_sc.push([buttons_scarico, buttons_carico, label]);
		}

		let icon = self.state.is_trasportatore ? "local_shipping" : "airline_seat_recline_extra";
		if (is_tech)
			icon = "star_outline";

		let user_label = self.state.user.username;
		if (is_trasportatore && self.state.mezzo) {
			user_label += " - " + self.state.mezzo.codice;
		}

		let btn_movs  = ""
		let btn_movs2 = ""

		if (is_trasportatore) {
			btn_movs =
				<ListGroup>
					<ListItem title="Registri" groupTitle/>
					<ListButton id={"link_movs"} className={"panel-close"}
								style={{borderBottom: "1px solid #dedede"}} key="movs_trasporti_key" title="Trasporti"
								href="/registro/Trasporti/"
					/>
					<ListButton id={"link_bolle"} className={"panel-close"}
								style={{borderBottom: "1px solid #dedede"}} key="movs_bolle_key" title="Bollette"
								href="/registro/Bollette/"
					/>
				</ListGroup>
			btn_movs2 = <ListButton id={"link_automezzo"} className={"panel-close"}
									style={{borderBottom: "1px solid #dedede"}} key="mezzi_link" title="Seleziona Automezzo"
									onClick={self.selectMezziOpen}
			/>
		}

		return (
			<Page name="home">

				<Navbar>
					<NavLeft><Link iconF7="menu" panelOpen="left"/></NavLeft>
					<NavTitle><img src={iride_logo} alt='logo' height="30rem"/>Bins App</NavTitle>
					<NavRight>
						<Link text={user_label} style={{fontSize: "1rem"}}
						      href={self.state.camion !== "" ? "/giacenza/" : ""}
						>
							<Icon material={icon}/>
						</Link>
						<Link onClick={() => self.logout(false)} iconOnly iconF7="exit" link="#"/>
					</NavRight>
				</Navbar>

				<IWPanel>
					{btn_movs}
					{btn_movs2}
				</IWPanel>

				{buttons_sc.map((buttons, i) => (
					<List className={"no-margin"} key={i}>
						<ListGroup>
							<ListItem title={buttons[2] + " - Scarico"} key={"scarico_" + i} groupTitle/>
							{buttons[0].map(button => (
								<ListButton style={{borderBottom: "1px solid #dedede"}} key={button.key}
								            title={button.title}
								            href={button.url + button.key + "/"}/>
							))}
						</ListGroup>

						<ListGroup>
							<ListItem title={buttons[2] + " - Carico"} key={"carico_" + i} groupTitle/>
							{buttons[1].map(button => (
								<ListButton style={{borderBottom: "1px solid #dedede"}} key={button.key}
								            title={button.title}
								            href={button.url + button.key + "/"}/>
							))}
						</ListGroup>
					</List>
				))}

				<List className={"display-none"}>
					<SelectAutomezzi
						id={'automezzi'}
						camion={self.state.camion}
						onChange={self.automezzoChanged}
					/>
				</List>
			</Page>
		);
	}
}

export default Home;