import DbEntity from "./DbEntity";

export default class Soci extends DbEntity {
	constructor() {
		super("soci", [['full_desc'], ['idanagrafica']]);
	}

	async filter(filters){

		//filters["descrizione"] = {$exists: true};
		try {
			return  await this.db.find({
				selector: {$and: [filters]},
				limit: 50,
				sort: ["full_desc"]
			});
		} catch (err) {
			console.log(err);
		}

	}

	async getByIdanagrafica(idanagrafica){
		try {
			return await this.db.find({
				selector:{
					idanagrafica: {$eq: idanagrafica}
				}
			})
		} catch (e) {
			console.log(e);
		}
	}

}