import DbEntity from "./DbEntity";
import moment from "moment";

export default class Trasporti extends DbEntity {
	constructor() {
		super("trasporti", [
			['_id', 'is_synced'], ['timestamp'], ['magazzino_sorgente'], ['magazzino_destinazione'], ['inventario'], ['barcode_pesate'], ['longitudine'], ['latitudine']
		]);

		/*this.db.createIndex({
			index: {
				fields: ['_id', 'is_synced']
			}
		}).then(function (result) {
			// handle result
			console.log("INDEX", result);
		}).catch(function (err) {
			console.log("INDEX", err);
		});*/

	}

	async getToSync() {
		try {
			const resp = await this.db.find({
				selector: {
					_id: {"$gte": null},
					is_synced: 0
				},
				sort: ['_id']
			});

			return resp;

		} catch (err) {
			console.log(err);
		}
	}

	async saveTrasportiFromView(view, synced) {
		if (!synced)
			synced = 0;
		const self = view;
		let mov = [];
		//let t = 0;

		self.state.bins.map(function (bin) {
			mov.push(bin);
			return mov;
		});

		if (!self.state.magazzino_sorgente._id)
			return new Error("Magazzino Sorgente Non Impostato");

		if (!self.state.magazzino_destinazione._id)
			return new Error("Magazzino Destinazione Non Impostato");

		if (mov.length === 0)
			return new Error("Nessun Cassone Movimentato");


		const trasp = await new Trasporti();
		const timestamp = moment().format("X");

		const imei = self.$f7.methods.androidReadIMEI();
		const id = imei !== "" ? imei + "_" + timestamp : timestamp;

		const resp = await trasp.insert({
			_id: id,
			magazzino_sorgente: self.state.magazzino_sorgente._id,
			magazzino_destinazione: self.state.magazzino_destinazione._id,
			timestamp: timestamp,
			imei: imei,
			bins: mov,
			is_synced: synced,
			inventario: self.state.inventario,
			barcode_pesate: self.state.barcode_pesate,
			latitudine: self.state.latitudine,
			longitudine: self.state.longitudine
		});

		return resp;
	}

	async getRegistro(params) {
		const start = params["start"];
		const end = params["end"];
		const idsocio = params["idsocio"];

		let selector = {
			timestamp: {$gte: start, $lte: end},
			$or: [
				{magazzino_sorgente: idsocio ? {$eq: idsocio.toString()} : {$gte: null}},
				{magazzino_destinazione: idsocio ? {$eq: idsocio.toString()} : {$gte: null}}
				]
		}

		try {
			const resp = await this.db.find({selector: selector, sort: ['timestamp']});
			return resp;
		} catch (e) {
			console.log(e);
		}
	}

	async getGiacenzaMezzo(idmezzo) {

		let selector = {
			timestamp: {$gte: null},
			$or: [
				{magazzino_sorgente: idmezzo},
				{magazzino_destinazione: idmezzo}
			]
		}

		try{
			const resp = await this.db.find({selector: selector, sort: ['timestamp']});
			return resp;
		} catch (e) {
			console.log(e);
		}
	}

}