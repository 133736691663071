import React from "react";
import {Link, Navbar, NavLeft, NavTitle, Page, Toolbar, List, ListItem, NavRight, Popup} from "framework7-react";
import iride_logo from "./assets/iride_logo.png";
import {IWDatePicker} from "./IWComponents";
import moment from "moment";
import Soci from "./entities/Soci";
import Users from "./entities/Users";
import Mezzi from "./entities/Mezzi";
import Deposito from "./entities/Deposito";

export default class Registro extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data: "",
			entity: undefined,
			rows: [],
			soci: [],
			filtro_socio: 0,
			filtro_socio_text: ""
		}
	}

	async componentDidMount() {
		const self = this;
		const par = self.props.opt;
		const time = moment();

		const ent = require(`./entities/${par}.js`).default;
		const ent_obj = await new ent();

		const soci = await new Soci();
		const soci_list = await soci.getAll();

		self.setState({
			data: time.format("DD/MM/YYYY"),
			entity: ent_obj,
			soci: soci_list.rows,
			filtro_socio: 0,
			filtro_socio_text: ""
		});

		await self.readEntities();
	}

	async readEntities() {
		const self = this;
		self.$f7.preloader.show();
		const {entity, data, filtro_socio} = self.state;


		let end_data = moment(data + " 23:59:59", "DD/MM/YYYY HH:mm:ss").add(1, 'day');

		let start_data = moment(data + +" 00:00:00", "DD/MM/YYYY HH:mm:ss");

		const res = await entity.getRegistro(
			{
				start: start_data.format("X"),
				end: end_data.format("X"),
				idsocio: filtro_socio
			}
		);

		//console.log(res);

		let rows = [];
		if (res && res.docs) {
			if (self.props.opt === "Bollette") {
				for (let i in Object.keys(res.docs)) {
					const soci = await new Soci();
					const socio = await soci.getByIdanagrafica(res.docs[i].idanagrafica);
					const date = moment.unix(res.docs[i].timestamp);

					const utenti = await new Users();
					const user = await utenti.getById(res.docs[i].idtrasportatore);

					rows.push({
						date: date.format("DD/MM/YYYY HH.mm"),
						socio: "[" + socio.docs[0].codice + "] " + socio.docs[0].descrizione,
						trasportatore: user.descrizione,
						bins: res.docs[i].ncolli,
						qolive: res.docs[i].qolive_dichiarate

					})
				}
			}

			if(self.props.opt === "Trasporti"){

				const mezzi = await new Mezzi();
				const soci = await new Soci();
				const dep = new Deposito();

				for (let i in Object.keys(res.docs)) {

					let mag_s = undefined;
					let mag_d = undefined;

					if(res.docs[i].magazzino_sorgente === dep._id)
						mag_s = dep;

					if(res.docs[i].magazzino_destinazione === dep._id)
						mag_d = dep;

					if(!mag_s)
						mag_s = await mezzi.getById(res.docs[i].magazzino_sorgente);
					if(!mag_d)
						mag_d = await mezzi.getById(res.docs[i].magazzino_destinazione);

					if(!mag_s){
						mag_s = await soci.getById(res.docs[i].magazzino_sorgente);
					}

					if(!mag_d){
						mag_d = await soci.getById(res.docs[i].magazzino_destinazione);
					}

					const date = moment.unix(res.docs[i].timestamp);
					rows.push({
						date: date.format("DD/MM/YYYY HH.mm"),
						trasporto_lista: "[" + mag_s.codice + "]  => [" + mag_d.codice + "]",
						trasporto_popup: "[" + mag_s.codice + "] " + mag_s.descrizione + " => [" + mag_d.codice + "] " + mag_d.descrizione,
						nbins: res.docs[i].bins.length,
						bins: res.docs[i].bins,
					})
				}
			}

		}
		self.setState({rows: rows}, () => {
			self.$f7.preloader.hide();
		});
	}

	handleDataClick() {
		const self = this;
		self.$$("#filtro_data").click();
	}

	handleDataChanged(e) {
		const self = this;
		const r = e.target.value

		self.setState({
			data: r.format("DD/MM/YYYY"),
			timestamp: r.format("X")
		}, () => self.readEntities().then());

	}

	handleSocioClick() {
		const self = this;
		self.$$("#filtro_soci").parent().click();
	}

	handleSocioChanged(res) {
		const self = this;
		const app = self.$f7;
		const smartSelect = app.smartSelect.get('.filtro_socio a');
		const idsocio = smartSelect.getValue();
		const socio_text = self.$$("#filtro_soci").find("option:checked").text();

		self.setState({
			filtro_socio: parseInt(idsocio),
			filtro_socio_text: socio_text
		}, () => {
			self.readEntities().then();
		});

	}

	render() {
		const self = this;

		let movs_list = [];
		let popup_list = [];

		if(self.props.opt === "Bollette"){
			movs_list = self.state.rows.map((row, i) => (
				<ListItem
					key={"reg_" + i}
					title={row.socio}
					after={row.date}
					subtitle={row.trasportatore}
					text={"N. Bins: " + row.bins + " Q. Olive: " + row.qolive + " Kg"}
					popupOpen={"#bollette_list-popup_" + i}
					link={"#"}
				/>
			))
			popup_list = self.state.rows.map((row, i) => (
				<Popup id={"bollette_list-popup_" + i}>
					<Page>
						<Navbar title={"Riepilogo Bolletta"}>
							<NavRight>
								<Link popupClose>Chiudi</Link>
							</NavRight>
						</Navbar>
						<List>
							<ListItem title={row.socio} />
							<ListItem title={"Data: " + row.date} />
							<ListItem title={"Trasportatore: " + row.trasportatore} />
							<ListItem title={"N. Bins: " + row.bins} />
							<ListItem title={"Q. Olive: " + row.qolive + " Kg"} />
						</List>
					</Page>
				</Popup>
			))
		}

		if(self.props.opt === "Trasporti"){
			movs_list = self.state.rows.map((row, i) => (
				<ListItem
					key={"reg_" + i}
					title={row.trasporto_lista}
					after={row.date}
					subtitle={"N. Bins: " + row.nbins}
					popupOpen={"#trasporti_list-popup_" + i}
					link={"#"}
				/>
			))
			popup_list = self.state.rows.map((row, i) => (
			<Popup id={"trasporti_list-popup_" + i}>
				<Page>
					<Navbar title={"Riepilogo Trasporto"}>
						<NavRight>
							<Link popupClose>Chiudi</Link>
						</NavRight>
					</Navbar>
					<List>
							<ListItem title={row.trasporto_popup} />
							<ListItem title={"Data: " + row.date} />
							<ListItem title={"N. Bins: " + row.nbins} />
							{row.bins.map((bin, index) => (
								<ListItem title={bin}/>
							))}

					</List>
				</Page>
			</Popup>
			))
		}

		return (
			<Page name="registro">
				<Navbar>
					<NavLeft backLink="Back"/>
					<NavTitle><img src={iride_logo} alt='logo' height="30rem"/>Registro {self.props.opt}</NavTitle>
				</Navbar>

				<Toolbar tabbar labels bottomMd>
					<Link tabLink="#tab-1"
					      text={self.state.filtro_socio === 0 ? "Cerca Per Socio" : self.state.filtro_socio_text}
					      iconF7="person"
					      onClick={self.handleSocioClick.bind(self)}/>

					<Link tabLink="#tab-2" iconF7="calendar" onClick={self.handleDataClick.bind(self)}>
						<input type="text" defaultValue={self.state.data} readOnly="readonly"
						       id="data_consegna_calendar" style={{textAlign: "center", width: "auto"}}/>
					</Link>
				</Toolbar>
				<IWDatePicker name={"filtro_data"} openIn={"sheet"} className={"display-none"} value={self.state.data}
				              onChange={self.handleDataChanged.bind(self)}/>

				<List mediaList>
					{movs_list}
					{popup_list}

					<ListItem
						title="Socio"
						className={"display-none filtro_socio"}
						smartSelect
						smartSelectParams={
							{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Socio', closeOnSelect: true}
						}
					>
						<select id="filtro_soci" name="filtro_soci" defaultValue={self.state.filtro_socio}
						        onChange={self.handleSocioChanged.bind(self)}>
							<option key={"no_anag"} value={0}/>
							{self.state.soci.map((socio, i) => (
								<option key={"anag_" + i}
								        value={self.props.opt === "Bollette" ? socio.doc.idanagrafica : socio.doc._id}>{socio.doc.full_desc}</option>
							))}
						</select>
					</ListItem>

				</List>

			</Page>
		)
	}
}