import React from 'react';
import {IWLocalAutocomplete, IWNumpad, ListItemSplit, IWBarcodeReader} from "../IWComponents";
import {
	Link,
	NavLeft,
	NavRight,
	Navbar,
	NavTitle,
	Page,
	List,
	ListItem,
	ListGroup,
	Icon,
	ListInput,
	ListButton,
	Toggle,
	BlockTitle
} from "framework7-react";
import Mezzi from "../entities/Mezzi";
import Dom7 from "dom7";
import Trasporti from "../entities/Trasporti";
import DestinazioneOlio from "../entities/DestinazioneOlio";
import iride_logo from "../assets/iride_logo.png";
import Bollette from "../entities/Bollette";
import moment from "moment";

export default class Bolletta extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			magazzino_destinazione: {},
			magazzino_sorgente: {
				cisterne: undefined
			},
			movimentazioni: [],
			tot_bins: 0,
			bins: [],
			peso_presunto: 0,
			destinazioni_olio: [],
			fusti_ritirati: 0,
			destinazioni_t: {T1: "Tutto", T2: "Resto"},
			destinazioni: {
				S: "Sfuso In Propri Fusti",
				L: "Lattine Non Filtrate",
				C: "In Cisterna Olma",
				P: "In Cisterna Personale",
			},
			bolletta_destinazioni: [],
			note: "",
			idcisterna_personale: 0,
			is_biologico: false
		};

		this.socioChanged = this.socioChanged.bind(this);
		this.destinazioniHandler = this.destinazioniHandler.bind(this);
		this.destinazioneChanged = this.destinazioneChanged.bind(this);
		this.destinazioneTChanged = this.destinazioneTChanged.bind(this);
		this.destinazioniPreSaveCheck = this.destinazioniPreSaveCheck.bind(this);
		this.bioToggle = this.bioToggle.bind(this);
	}

	componentDidMount() {
		const self = this;
		const camion = localStorage.getItem("camion")
		if (camion !== "") {
			new Mezzi().getById(camion).then(r => {
				self.setState({"magazzino_destinazione": r});
			});
		}

		//self.destinazioniHandler();
	}

	socioChanged(event) {
		const self = this;
		const target = event.target;
		const name = target.name;
		const value = target.value;

		if(!value.cisterne){
			value.cisterne = {}
		}

		self.setState({
			[name]: value,
		});

		self.setState({bolletta_destinazioni: []});
		self.destinazioniHandler();

	}

	barcodeHandler(ret) {
		const self = this;
		self.setState({bins: ret, tot_bins: ret.length});
	}

	pesoPresuntoHandler(numpad) {
		const self = this;
		const val = parseFloat(numpad.target.value);

		self.setState({
			peso_presunto: val
		});
	}

	fustiRitiratiHandler(numpad) {
		const self = this;
		const val = parseFloat(numpad.target.value);

		self.setState({
			fusti_ritirati: val
		});
	}

	destQHandler(numpad) {
		const self = this;

		const name = numpad.target.name;
		const index = parseInt(name.replace("destinazioneQ_", ""));
		let val;
		val = parseFloat(numpad.target.value) || 0;

		let dests_bolletta = self.state.bolletta_destinazioni;
		dests_bolletta[index]["destinazioneQ"] = val;

		self.setState({bolletta_destinazioni: dests_bolletta});

	}

	destinazioniHandler(i = "") {
		const self = this;
		if (i === ""){
			let boll_dests = self.state.bolletta_destinazioni;
			if (boll_dests.length < 3) {
				boll_dests.push(new DestinazioneOlio());
			}
			self.setState({bolletta_destinazioni: boll_dests});
		}
		else {
			const app = self.$f7;

			app.dialog.create({
				title: "Bins App",
				text: 'Vuoi rimuovere la riga Destinazione Olio?',
				cssClass: "iw-dialog",
				buttons: [
					{
						text: 'Annulla',
					},
					{
						text: 'Ok',
					}
				],
				onClick: function (dialog, ind) {
					if (ind === 1) {
						let boll_dests = self.state.bolletta_destinazioni;

						boll_dests.pop()
						self.setState({bolletta_destinazioni: boll_dests});

						let cp = 0;
						boll_dests.map(function (v,k){
							//console.log(v,k);
							//console.log(v["destinazioneD"]);
							if (v["destinazioneD"] === "P"){
								cp++;
							}
						});

						if (cp === 0){
							self.setState({idcisterna_personale: 0});
						}
					}
				}
			}).open();
		}
	}

	destinazioneChanged(e, i) {
		const self = this;
		const $$ = Dom7;

		let name = e.target.name;

		name = name.replace(/_\d$/gm, "");

		//console.log(e.target.value.indexOf("T"));
		//console.log(self.state.bolletta_destinazioni[i]["destinazioneD"],e.target.name, e.target.value);

		if (e.target.name.indexOf("cisterna_personale_"+i) > -1) {
			self.setState({idcisterna_personale: e.target.value});
		}
		if (e.target.value === "P") {
			$$("#cisterna_personale_" + i).change();
		}

		if (name === "cisterna_personale")
			return false;

		let dests_bolletta = self.state.bolletta_destinazioni;
		dests_bolletta[i][name] = e.target.value;

		self.setState({bolletta_destinazioni: dests_bolletta});
	}

	destinazioneTChanged(e, i) {
		this.destinazioneChanged(e, i);
	}

	bioToggle(e) {
		const self = this;
		self.setState({is_biologico: e})
	}

	noteHandler(e){
		const self = this;
		self.setState({note: e.target.value});
	}

	destinazioniPreSaveCheck() {
		const self = this;
		const {bolletta_destinazioni} = self.state;
		const peso_presunto = self.state.peso_presunto;
		const totale_bins = self.state.tot_bins;
		const fusti_ritirati = self.state.fusti_ritirati;

		//console.log("BOLLETTA SALVATAGGIO PER CISTERNA");
		//console.log(self.state);

		if (!bolletta_destinazioni[0].destinazioneD)
			return new Error("Destinazione 1 Non Impostata");


		let t_check = false;
		let err_msg = "";
		for (let i of [0, 1, 2]) {
			const d_field = "destinazioneD";
			const q_field = "destinazioneQ";
			const t_field = "destinazioneT";

			if (!bolletta_destinazioni[i] || !bolletta_destinazioni[i][d_field]) {
				continue;
			}
			if (bolletta_destinazioni[i][t_field] && bolletta_destinazioni[i][t_field] !== "0")
				t_check = true;

			if ((!bolletta_destinazioni[i][t_field] || bolletta_destinazioni[i][t_field] === "0") && (!bolletta_destinazioni[i][q_field] || bolletta_destinazioni[i][q_field] === "0")) {
				err_msg += "Inserire Una Quantità/Kg Per Destinazione" + (i + 1).toString() + "<br/>";
			}
			if (bolletta_destinazioni[i][d_field] === "S" && (fusti_ritirati===0))
				err_msg += "Inserire Num. Fusti" + "<br/>";
		}
		if (!peso_presunto || peso_presunto === "0"){
			t_check = true;
			err_msg += "Inserire il Peso Presunto<br/>";
		}

		if (!totale_bins || totale_bins === "0"){
			t_check = true;
			err_msg += "Nessun Bin è stato sparato<br/>";
		}

		if (!t_check)
			err_msg += "Inserire Almeno Una Destinazione Con Tutto O Resto";

		return err_msg;
	}

	async saveBolletta() {
		const self = this;
		const app = self.$f7;

		let coordinates = JSON.parse(app.methods.androidCoordinates());
		self.setState({latitudine: coordinates.lat});
		self.setState({longitudine: coordinates.lon});
		console.log(coordinates);

		app.dialog.create({
			title: "Salvataggio Bolletta",
			text: 'Effettuare il salvataggio?',
			cssClass: "iw-dialog",
			buttons: [
				{
					text: 'Annulla',
				},
				{
					text: 'Ok',
				}
			],
			onClick: async function (dialog, ind) {
				if (ind === 1) {

					let err_bolletta = self.destinazioniPreSaveCheck();

					if (err_bolletta !== "")
						return app.methods.showIWResponse(new Error(err_bolletta));

					const trasp = await new Trasporti();

					const resp_trasp = await trasp.saveTrasportiFromView(self, 1);

					if (resp_trasp.ok) {
						const user = JSON.parse(localStorage.getItem("user"));
						const timestamp = moment().format("X");
						const imei = app.methods.androidReadIMEI();

						const id = imei !== "" ? imei + "_" + timestamp : timestamp;

						console.log("SALVATAGGIO BOLLETTA");
						console.log(self.state);

						let note = self.state.note;
						if (self.state.idcisterna_personale > 0) {
							//console.log(self.state.magazzino_sorgente.cisterne,self.state.idcisterna_personale);
							note += " - CISTERNA PERSONALE SELEZIONATA: " + self.state.magazzino_sorgente.cisterne[self.state.idcisterna_personale];
						}

						let bolletta = {
							_id: id,
							idanagrafica: self.state.magazzino_sorgente.idanagrafica,
							ncolli: self.state.bins.length,
							nfusti: self.state.fusti_ritirati,
							qolive_dichiarate: self.state.peso_presunto,
							is_biologico: self.state.is_biologico,
							idtrasportatore: user._id === "tech" ? 1 : user._id,
							idcisterna_personale: self.state.idcisterna_personale,
							timestamp: timestamp,
							destinazioni: self.state.bolletta_destinazioni,
							idtrasporto: resp_trasp.id,
							note: note,
							is_synced: 0
						}

						//console.log("DESTINAZIONI SALVATAGGIO: ");
						//console.log(self.state.bolletta_destinazioni);

						const bolla = await new Bollette();
						const resp = await bolla.insert(bolletta);

						app.methods.showIWResponse(resp);
						if (resp.ok) {
							app.methods.bgSync(2);
							self.$f7router.navigate("/home/");
						}
					}
				}
			}
		}).open();
	}

	resetPage() {
		const self = this;
		const app = self.$f7;

		app.dialog.create({
			title: "Bins App",
			text: 'Ricaricare La Pagina? Tutte Le Modifiche Non Salvate Andranno Perse',
			cssClass: "iw-dialog",
			buttons: [
				{
					text: 'Annulla',
				},
				{
					text: 'Ok',
				}
			],
			onClick: function (dialog, index) {
				if(index === 1){
					app.views.main.router.refreshPage();
				}
			}
		}).open();
	}

	render() {
		const self = this;
		return (
			<Page name="bolletta">
				<Navbar>
					<NavLeft backLink="Back" backLinkForce backLinkUrl={"/home/"}/>
					<NavTitle><img src={iride_logo} alt='logo' height="30rem"/>{"Emissione Bolletta"}</NavTitle>
					<NavRight>
						<Link onClick={self.resetPage.bind(self)}>Reset</Link>
						<Link onClick={self.saveBolletta.bind(this)}>Salva</Link>
					</NavRight>
				</Navbar>
				<BlockTitle strong>{"Emissione Bolletta"}</BlockTitle>
				<List className={"no-margin no-padding"}>
					<IWLocalAutocomplete
						title={self.state.magazzino_sorgente.is_bio !== undefined ? self.state.magazzino_sorgente.is_bio !== "0" ? "Socio Bio" : "Socio": "Socio"}
						id={"magazzino_sorgente"}
						type={"Soci"}
						onChange={self.socioChanged}
						autofocus={true}
					/>

					<IWBarcodeReader
						barcodeHandler={self.barcodeHandler.bind(self)}
						magazzinoSorgente={self.state.magazzino_sorgente}
						magazzinoDestinazione={self.state.magazzino_destinazione}
					/>
					<ListItem title={"Peso Presunto Olive (Kg):"}>
						<IWNumpad name="peso_presunto"
						          value={self.state.peso_presunto}
						          onChange={self.pesoPresuntoHandler.bind(this)}
						/>
					</ListItem>

					<ListItem className={self.state.magazzino_sorgente.is_bio !== "0" ? "" : "display-none"}>
						<span>Olive Biologiche</span>
						<Toggle name={"is_biologico"} onToggleChange={e => self.bioToggle(e)}
						/>
					</ListItem>

					{self.state.bolletta_destinazioni.map((dest, i) => (
						<ListGroup key={"group" + i}>
							<ListItemSplit key={"row_split_header_" + i} name={"row_split_header_" + i}>
								<ListItem title={"Destinazione Olio " + (i + 1).toString()} key={"dgroup_" + i}
								          groupTitle/>
								<ListButton groupTitle style={{backgroundColor: "var(--f7-list-group-title-bg-color)"}}
								            onClick={this.destinazioniHandler.bind(self,"")}>
									<Icon material={"add"} key={"add_btn" + i} color={"#007aff"}/>
								</ListButton>
								{self.state.bolletta_destinazioni.length > 1 && self.state.bolletta_destinazioni.length - 1 <= i  ?
									<ListButton groupTitle
												style={{backgroundColor: "var(--f7-list-group-title-bg-color)"}}
												onClick={this.destinazioniHandler.bind(self, i)}>
										<Icon material={"remove"} key={"remove_btn" + i} color={"red"}/>
									</ListButton>
								: ""}
							</ListItemSplit>

							<ListItem
								title={"Destinazione"}
								smartSelect
								smartSelectParams={{openIn: 'sheet', closeOnSelect: true}}
								key={"destinazione" + i}
							>
								<select name={"destinazioneD_" + i} defaultValue={dest.destinazioneD}
								        key={"destinazione_sel" + i}
								        onChange={e => self.destinazioneChanged(e, i)}>
									<option value={"0"}/>
									{Object.keys(self.state.destinazioni).map(d => (
										<option key={d + "_" + i} value={d}>{self.state.destinazioni[d]}</option>
									))}
								</select>
							</ListItem>

							<ListItem
								className={self.state.bolletta_destinazioni[i]["destinazioneD"] === "P" ? "" : "display-none"}
								title={"Cisterna"}
								smartSelect
								smartSelectParams={{openIn: 'sheet', closeOnSelect: true}}
								key={"cisterna_personale" + i}
							>
								<select id={"cisterna_personale_" + i} name={"cisterna_personale_" + i}
								        defaultValue={self.state.idcisterna_personale}
								        key={"cp_sel" + i} onChange={e => self.destinazioneChanged(e, i)}>
									{Object.keys(self.state.magazzino_sorgente.cisterne).map(c => (
										<option key={c + "_" + i}
										        value={c}>{self.state.magazzino_sorgente.cisterne[c]}</option>
									))}
								</select>
							</ListItem>


							<ListItemSplit key={"split_row_" + i} name={"split_row_" + i}>
								<ListItem className={"no-padding"} title={
									self.state.bolletta_destinazioni[i]["destinazioneD"] === "L" ? "Pz" : "Kg"
								}
								          disabled={self.state.bolletta_destinazioni["destinazioneT_" + i] && self.state.bolletta_destinazioni["destinazioneT_" + i] !== "0"}>
									<IWNumpad name={"destinazioneQ_" + i}
									          value={dest.destinazioneQ}
									          onChange={self.destQHandler.bind(this)}
									/>
								</ListItem>
								<ListItem
									title={"Quantità"}
									smartSelect
									smartSelectParams={{openIn: 'sheet', closeOnSelect: true}}
									key={"destinazione_t" + i}
									disabled={parseInt(dest.destinazioneQ) !== 0}
								>
									<select name={"destinazioneT_" + i} defaultValue={dest.destinazioneT}
									        key={"destinazione_t_sel" + i}
									        onChange={e => self.destinazioneTChanged(e, i)}>
										<option value={0}/>
										{Object.keys(self.state.destinazioni_t).map(t => (
											<option key={t + "_" + i} value={t}>{self.state.destinazioni_t[t]}</option>
										))}
									</select>
								</ListItem>
							</ListItemSplit>
						</ListGroup>
					))}

					<List key={"dati_aggiuntivi"}>
						<ListItem title={"Nr. Fusti Ritirati:"}>
							<IWNumpad name="fusti_ritirati"
							          value={self.state.fusti_ritirati}
							          onChange={self.fustiRitiratiHandler.bind(this)}
							/>
						</ListItem>
						<ListInput
							label="Note"
							type="textarea"
							value={self.state.note}
							onInput={self.noteHandler.bind(this)}
						/>
					</List>
				</List>
			</Page>
		);
	}

}