import IWAjax from "./IWAjax";
import moment from "moment";

export default class Synchronization {
	constructor() {
		this.read = [
			{key: 0, title: "Deposito", fn: "readDeposito", obj: "Deposito", res: "deposito"},
			{key: 1, title: "Utenti", fn: "readUtenti", obj: "Users", res: "utenti"},
			{key: 2, title: "Soci", fn: "readSoci", obj: "Soci", res: "soci"},
			{key: 3, title: "Mezzi", fn: "readMezzi", obj: "Mezzi", res: "mezzi"},
		];

		this.write = [
			{key: 4, title: "Movimentazioni", fn: "writeEntities", obj: "Trasporti", res: "trasporti"},
			{key: 5, title: "Bollette", fn: "writeEntities", obj: "Bollette", res: "bollette"},
			//{key: 4, title: "Inventario", fn: "writeEntities", obj: "Trasporti", res: "inventario"},
		]
	}

	async receiveSync(type, data, key, callback, app) {

		return IWAjax({
			app: app,
			route: data,
			data: {},
			showPreloader: false,
			success: async function (res) {

				let resp = false;
				if (type === "Deposito") {
					localStorage.setItem("olma_deposito", JSON.stringify(res.data));
					resp = true;
				}

				if (res.data && res.data[key]) {
					let ent = require(`../entities/${type}.js`).default;
					let ent_obj = await new ent();

					await ent_obj.destroyDb();
					//ent_obj.db.close();

					//ent = require(`../entities/${type}.js`).default;
					ent_obj = await new ent();

					resp = await ent_obj.multiInsert(res.data[key]);
					const ok_res = Object.values(resp).filter((v) => v.ok === true).length;
					ent_obj = await new ent();
					if(type === "Users")
						ent_obj.populate();
					resp = ok_res === resp.length;
				}
				if (typeof callback === "function")
					return callback(key, resp);
			},
			error: function (error) {
				if (typeof callback === "function")
					return callback(key, new Error(error));
			}
		});
	}

	async sendSync(type, data, key, callback, app) {

		const ent = require(`../entities/${type}.js`).default;
		const ent_obj = await new ent();

		const ents = await ent_obj.getToSync();
		console.log("in sync");
		console.log(ents);

		if (ents.docs.length === 0) {
			//self.setAjaxResult(key, true, callback);
			if (typeof callback === "function")
				callback(key, false);
			return false;
		}

		ents.entity = key;

		return IWAjax({
			app: app,
			route: data,
			data: ents,
			showPreloader: false,
			success: async function (res) {
				let resp = false;
				if (res.data && res.data.ret && res.data.info) {
					let docs = [];
					for (let k of Object.keys(res.data.info)) {
						const r = res.data.info[k];
						console.log("GESTIONE ERRORE 1");
						if (!r.error) {
							const doc = await ent_obj.getById(k);
							doc.is_synced = 1;
							docs.push(doc);
						}
					}
					resp = await ent_obj.multiInsert(docs);
					//console.log(resp);
				}
				if (typeof callback === "function")
					return callback(key, resp);
			},
			error: function (error) {
				console.log("GESTIONE ERRORE 2");
				console.log(error);
				if (typeof callback === "function")
					return callback(key, new Error(error));
			}
		});
	}

	getReadPromises() {
		const self = this;
		return self.read.map(sb => {
			return new Promise(async function (r) {
				await self.receiveSync(sb.obj, sb.fn, sb.res, function (res) {
					return self.setUpdatesResult(res, sb, r);
				});
			});
		});
	}

	setUpdatesResult(res, sb, r) {
		let t = moment().format('DD-MM-YYYY HH:mm:ss');
		const val = res instanceof Error ? "0" : "1";
		localStorage.setItem("iwsync_" + sb.res + "_last", t);
		localStorage.setItem("iwsync_" + sb.res + "_res", val);

		if (res instanceof Error) {
			r(sb.title + " => " + res.message);
		} else {
			r("");
		}
		return r;
	}

	getWritePromises() {
		const self = this;
		return self.write.map(sb => {
			return new Promise(async function (r) {
				await self.sendSync(sb.obj, sb.fn, sb.res, function (res) {
					return self.setUpdatesResult(res, sb, r);
				});
			});
		});
	}

	syncStart(mode) {
		const self = this;

		if(!mode)
			mode = 0;

		let promisesr = [];
		let promisesw = [];

		if(parseInt(mode) === 0){
			promisesr = self.getReadPromises();
			promisesw = self.getWritePromises();
		}

		if(parseInt(mode) === 1)
			promisesr = self.getReadPromises();

		if(parseInt(mode) === 2)
			promisesw = self.getWritePromises();

		console.log(promisesr);
		console.log(promisesw);
		console.log("in sync");

		return Promise.all([...promisesr, ...promisesw]).then(r => {
			let msg = r.join("<br/>");
			console.log("SYNC COMPLETE1", r);
			return msg;
		});
	}
}