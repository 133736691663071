import React from 'react';
//import IWAjax from "./utils/IWAjax";

import {
	Link,
	List,
	NavLeft,
	NavRight,
	ListItem,
	Page,
} from "framework7-react";
import {IWHeader} from "./IWComponents";
import moment from "moment";
import Synchronization from "./utils/Synchronization";


class Sync extends React.Component {

	constructor(props) {
		super(props);

		this.sync = new Synchronization();

		this.state = {
			buttons: this.sync.read,
			send_buttons: this.sync.write
		};

		this.syncAll = this.syncAll.bind(this);
		this.setAjaxResult = this.setAjaxResult.bind(this);
	}

	async componentDidMount() {
		/*const $$ = Dom7;
		console.log($$(".panel-close"));
		$$(".panel-close").removeClass("panel-close");*/
		const self = this;

		if (self.$f7.panel.get("left"))
			self.$f7.panel.get("left").close(true);

		const dep = localStorage.getItem("olma_deposito");
		if (!dep) {
			await self.syncAll();
		}
	}

	async receiveSync(type, data, key, callback) {
		const self = this;
		try{
			self.$f7.preloader.show();

			return await this.sync.receiveSync(type, data, key, function (key, res) {
				return self.setAjaxResult(key, res);
			}, self.$f7);
		}
		catch(e){
			console.log("Errore in receiveSync: "+e);
		}
	}

	async sendSync(type, data, key, callback) {

		const self = this;
		try{
			self.$f7.preloader.show();
			return await this.sync.sendSync(type, data, key, function (key, res) {
				return self.setAjaxResult(key, res);

			}, self.$f7);
		}
		catch(e){
			console.log("Errore sendSync: "+e);
		}
	}

	setAjaxResult(key, res, callback) {
		const self = this;
		let t = moment().format('DD-MM-YYYY HH:mm:ss');
		const val = res instanceof Error ? "0" : "1";
		localStorage.setItem("iwsync_" + key + "_last", t);
		localStorage.setItem("iwsync_" + key + "_res", val);
		//console.log("in setAjaxResult");

		if (typeof callback === "function") {
			callback(res);
		} else {
			try{
				self.$f7.preloader.hide();
			}
			catch(e){
				console.log("Errore in setAjaxResult: "+e);
			}
			finally{
				self.$f7.preloader.hide();
				if (res instanceof Error)
					self.$f7.methods.showIWResponse(res);
				self.forceUpdate();
			}
			return res;
		}
	}

	async syncAll() {
		const self = this;
		try {
			self.$f7.preloader.show();
			self.sync.syncStart().then(() => {
				try {
					self.$f7.preloader.hide();
				}
				catch (e){
					console.log("Errore preloader hide in syncAll: "+e);
				}
				finally {
					self.$f7.preloader.hide();
					self.forceUpdate();
				}
			});
		}
		catch (e){
			console.log("Errore in SyncAll: "+e);
		}
	}

	render() {
		const self = this;
		const buttons = self.state.buttons;
		const send_buttons = self.state.send_buttons;

		return (
			<Page name="sync">
				<IWHeader title="Seleziona un'attività">
					<NavLeft>
						<Link onClick={() => self.$f7router.navigate('/home/')} iconOnly iconF7="home" link="#"/>
					</NavLeft>
					<NavRight>
						<Link onClick={() => self.syncAll()} iconOnly iconMaterial="sync" link="#"/>
					</NavRight>
				</IWHeader>

				<List>
					{buttons.map(button => (
						<ListItem style={{borderBottom: "1px solid #dedede"}} key={button.key} title={button.title}
						          href="#" onClick={() => self.receiveSync(button.obj, button.fn, button.res)}
						          footer={localStorage.getItem("iwsync_" + button.res + "_last")}
						>
							<div
								className={"chip chip-outline " + (localStorage.getItem("iwsync_" + button.res + "_res") === "1" ? "color-green" : "color-red")}>
								<div
									className="chip-label">{localStorage.getItem("iwsync_" + button.res + "_res") === "1" ? "Ok" : "Fail"}</div>
							</div>
						</ListItem>
					))}
				</List>

				<List>
					{send_buttons.map(button => (
						<ListItem style={{borderBottom: "1px solid #dedede"}} key={button.key} title={button.title}
						          href="#" onClick={() => self.sendSync(button.obj, button.fn, button.res)}
						          footer={localStorage.getItem("iwsync_" + button.res + "_last")}
						>
							<div
								className={"chip chip-outline " + (localStorage.getItem("iwsync_" + button.res + "_res") === "1" ? "color-green" : "color-red")}>
								<div
									className="chip-label">{localStorage.getItem("iwsync_" + button.res + "_res") === "1" ? "Ok" : "Fail"}</div>
							</div>
						</ListItem>
					))}
				</List>

			</Page>
		);
	}
}

export default Sync;