import PouchDB from 'pouchdb';

PouchDB.plugin(require('pouchdb-find').default);
//PouchDB.plugin(require('pouchdb-quick-search'));


export default class DbEntity {
	constructor(name, indexes) {
		this.name = name;
		this.indexes = indexes
		this.db = new PouchDB(name, {auto_compaction: true});

		if(this.indexes){
			return (async () => {
				await this.setIndexes(indexes);
				return this
			}).call(this);
		}
	}

	async setIndexes(fields) {
		try {
			for (let field in Object.keys(fields)) {
				await this.db.createIndex({
					index: {
						fields: fields[field]
					}
				});
			}
			return this
		} catch (err) {
			console.log("INDEX", err);
		}
	}

	async insert(doc) {
		try {
			return await this.db.put(doc);
		} catch (err) {
			console.log(err);
		}
	}

	async multiInsert(data) {
		try {
			let response = await this.db.bulkDocs(data);
			//console.log(response);
			let i = 0;
			for (const res of response) {
				if (res.status === 409) {
					//console.log(res, data[i]);
					const doc = data[i];
					try {
						const old_doc = await this.db.get(doc._id);
						doc._rev = old_doc._rev;
						const re = await this.db.put(doc, {force: true});
						response[i] = re;
						//console.log(response);
					} catch (e) {
						console.log(e);
					}
				}
				i++;
			}

			//const info = await this.db.info();
			//console.log(info);

			return response;

		} catch (err) {
			console.log(err);

		}
	}

	async getAll() {
		try {
			return await this.db.allDocs({
				include_docs: true
			});

		} catch (err) {
			console.log(err);
		}
	}

	async getById(id) {
		try {
			const doc = await this.db.get(id);
			return doc;
		} catch (err) {
			console.log(err);
		}
	}

	async destroyDb() {
		try {
			return await this.db.destroy();
			//await this.db.close();
			//return constructor(this.name);
		} catch (e) {
			console.log(e);
		}
	}
}