import React from "react";
import {
	BlockTitle,
	Input,
	Button,
	List,
	ListItem,
	Navbar,
	NavTitle,
	ListInput,
	NavLeft,
	NavRight, ListButton, Panel,
	ListGroup, Page, Link, Popup, Icon
} from "framework7-react";
import moment from "moment";
import IWAjax from "./utils/IWAjax";
import iride_logo from './assets/iride_logo.png'
import Mezzi from "./entities/Mezzi";

export class IWDatePicker extends React.Component {

	componentDidMount() {
		const self = this;
		const app = self.$f7;
		let openin = self.props.openIn ? self.props.openIn : 'auto';
		app.calendar.create({
			inputEl: '#' + self.props.name,
			dateFormat: "dd/mm/yyyy",
			closeOnSelect: true,
			openIn: openin,
			monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
			monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
			dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
			dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
			on: {
				dayClick: function (calendar, dayEl, year, month, day) {
					if (typeof self.props.onChange === "function") {
						self.props.onChange({
							target: {
								calendar: calendar,
								dayEl: dayEl,
								value: moment(day + "/" + parseInt(month + 1) + "/" + year, "DD/MM/YYYY"),
								name: self.props.name,
								type: "datepicker"
							}
						});
					}
				}
			}
		});
	}

	render() {
		return (
			<div className={this.props.className}>
				{this.props.label}
				<ul>
					<li>
						<input ref={this.props.name} type="text" placeholder={this.props.placeholder}
						       readOnly="readonly" id={this.props.name} onChange={this.props.onChange}
						       name={this.props.name} value={this.props.value}
						       style={{borderBottom: "1px #dedede solid", maxWidth: "10em"}}/>
					</li>
				</ul>
			</div>
		)
	}
}


export class IWTimePicker extends React.Component {

	componentDidMount() {
		const self = this;
		const app = self.$f7;
		app.picker.create({
			inputEl: '#' + self.props.name,
			rotateEffect: true,
			formatValue: function (values, displayValues) {
				return values[0] + '.' + values[1];
			},
			cols: [
				// Hours
				{
					values: (function () {
						var arr = [];
						for (let i = 0; i <= 23; i++) {
							arr.push(i < 10 ? '0' + i : i);
						}
						return arr;
					})(),
				},
				// Divider
				{
					divider: true,
					content: '.'
				},
				// Minutes
				{
					values: (function () {
						var arr = [];
						for (var i = 0; i <= 59; i++) {
							arr.push(i < 10 ? '0' + i : i);
						}
						return arr;
					})(),
				}
			],
			on: {
				change: function (picker, value, displayValue) {
					if (value[0] !== "00" && value[1] !== "00")
						self.props.onChange(picker, value, displayValue);
				},
				open: function (picker) {
					picker.setValue(self.props.value.split("."));
				}
			}
		});
	}

	render() {
		return (
			<div>
				{this.props.label}
				<ul>
					<li>
						<input type="text" placeholder={this.props.placeholder} readOnly="readonly" id={this.props.name}
						       onChange={this.props.onChange} name={this.props.name} value={this.props.value}
						       style={{borderBottom: "1px #dedede solid", maxWidth: "10em"}}/>
					</li>
				</ul>
			</div>
		)
	}
}

export class IWNumpad extends React.Component {

	componentDidMount() {
		const self = this;
		const app = self.$f7;

		app.keypad.create({
			inputEl: "#" + self.props.name,
			toolbarCloseText: "Fine",
			formatValue: function (value) {
				self.props.onChange({
					target: {
						value: value,
						name: self.props.name,
						type: "numpad"
					}
				});
			},
			on: {
				close(keypad) {
					if (typeof self.props.onClose === "function") {
						self.props.onClose();
					}
				},
				open(keypad) {
					keypad.value = "";
				}
			}
		});
	}

	render() {
		let wsize = this.props.wsize ? this.props.wsize : 'auto';
		return (
			<span style={{width: wsize, maxWidth: "4rem"}}>
                <Input label={this.props.label} type="text" className="numpad" id={this.props.name}
                       errorMessage="Compilare una quantità valida!" name={this.props.name} value={this.props.value}
                       required validate pattern="[0-9]+([\.,][0-9]+)?" onChange={this.props.onChange}
                       onClose={this.props.onClose} style={{textAlign: "right"}} readonly/>
            </span>
		);
	}
}

export class IWAutocomplete extends React.Component {
	componentDidMount() {
		const self = this;
		const app = self.$f7;

		app.autocomplete.create({
			openIn: 'popup',
			openerEl: '#' + self.props.id,
			valueProperty: 'id',
			textProperty: 'text',
			limit: 50,
			expandInput: true,
			closeOnSelect: true,
			source: function (query, render) {
				const autocomplete = this;
				let results = [];
				if (query.length === 0) {
					render(results);
					return;
				}
				// Show Preloader
				autocomplete.preloaderShow();

				IWAjax({
					app: app,
					data: {
						query: query
					},
					showPreloader: false,
					route: self.props.route,
					success: function (res) {
						autocomplete.preloaderHide();
						if (!res.data) return false;
						for (let i = 0; i < res.data.length; i++) {
							if (res.data[i].text.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(res.data[i]);
						}
						render(results);
					}
				})
			},
			on: {
				change: function (value) {
					self.props.onChange({
						target: {
							value: value[0],
							name: self.props.id,
							type: "autocomplete"
						}
					});
					if (self.props.onChanged) {
						let params = self.props.onChangedParams;
						if (!params) params = [];
						params.id = value[0].id;
						self.props.onChanged(params);
					}

					if (self.props.setItemAfter)
						document.getElementById(self.props.id).getElementsByClassName("item-after")[0].textContent = value[0].descrizione;
				}
			}
		})
	}

	render() {
		return (
			<List>
				<ListItem id={this.props.id} title={this.props.title} link="#" popoverClose={this.props.popoverClose}
				          after={this.props.setItemAfter ? "Cerca" : ""}/>
			</List>
		)
	}
}

export class IWHeader extends React.Component {

	render() {

		let nav_left = <NavLeft/>;
		let nav_right = <NavRight/>;
		if (this.props.children && this.props.children.length > 0) {
			this.props.children.forEach(function (child) {
				if (child.type.displayName === "f7-nav-left")
					nav_left = child;
				if (child.type.displayName === "f7-nav-right")
					nav_right = child;
			});
			//<BlockTitle style={{fontSize: "1.5rem", height: "1.5rem"}}></BlockTitle>
		}
		return (
			<Navbar>
				{nav_left}
				<NavTitle>
					<img src={iride_logo} alt='logo' height="30rem"/>{this.props.title}
				</NavTitle>
				{nav_right}
			</Navbar>
		)
	}
}

export class IWPanel extends React.Component {
	render() {
		const self = this;
		const childrens = self.props.children || [];
		return (
			<Panel left reveal>
				<BlockTitle>Impostazioni</BlockTitle>
				<List style={{height: "calc(100% - 9em)", overflow: "auto"}}>
					<ListButton panelClose
								style={{borderBottom: "1px solid #dedede"}} key="home"
					            title="Chiudi"
					            href="/home"/>
					<ListButton style={{borderBottom: "1px solid #dedede"}} key="sync"
					            title="Sincronizzazione"
					            href="/sync/"/>
					{childrens.map(children => (
						children
					))}

				</List>

				<BlockTitle>
					Olma Bins App: {global.appVersion}<br/>
					WebView: {self.$f7.methods.androidAppVersion()}<br/>
					Imei: {self.$f7.methods.androidReadIMEI()}
				</BlockTitle>

			</Panel>
		)
		/*
		<ListButton panelClose
								style={{borderBottom: "1px solid #dedede"}} key="inventory"
								title="Inventario"
								href="/magazzino/movimentazione/o2o"/>
		 */
	}
}

export class IWLocalAutocomplete extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			source: []
		}
	}

	async componentDidMount() {
		const self = this;
		const app = self.$f7;

		const type = self.props.type;
		const ent = require(`./entities/${type}.js`).default;
		const ent_obj = await new ent();

		const source = await ent_obj.db.allDocs({include_docs: true});
		self.setState({source: source});

		app.autocomplete.create({
			openIn: self.props.openIn ? self.props.openIn : 'dropdown',
			inputEl: '#' + self.props.id + ' input',
			valueProperty: 'full_desc',
			textProperty: 'full_desc',
			expandInput: true,
			limit: 50,
			preloader: true,
			source: async function (query, render) {
				const autocomplete = this;

				let results = [];
				if (query.length < 2) {
					await render(results);
					return;
				}
				// Show Preloader
				autocomplete.preloaderShow();

				let res = [];

				for (let i = 0; i < self.state.source.rows.length; i++) {
					if (res.length > 24)
						break;
					const doc = self.state.source.rows[i].doc;
					if (doc.full_desc && doc.full_desc.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
						res.push(doc);
					}
				}

				await render(res);
				return autocomplete.preloaderHide();
			},
			on: {
				change: function (value) {
					if (value) {
						if (typeof self.props.onChange === "function") {
							self.props.onChange({
								target: {
									value: value[0],
									name: self.props.id,
									type: "autocomplete"
								}
							});
							if (self.props.onChanged) {
								let params = self.props.onChangedParams;
								if (!params) params = [];
								params.id = value[0].id;
								self.props.onChanged(params);
							}
						}
					}
				}
			}
		})
	}

	render() {
		return (
			<ListInput style={{listStyle: "none"}}
			           id={this.props.id}
			           label={this.props.title}
			           type="text"
			           autofocus={false}
			           className={this.props.className}
			>
			</ListInput>
		)
	}
}

export class SelectAutomezzi extends React.Component {

	constructor(props) {
		super(props);
		this.state = {automezzi: []};
	}

	async componentDidMount() {
		const self = this;
		const mezzi = await new Mezzi();
		const automezzi = await mezzi.getAll();
		self.setState({automezzi: automezzi.rows});
	}

	render() {
		const self = this;
		return (
			<ListItem title="Selezione Automezzo" smartSelect smartSelectParams={{closeOnSelect: true}}
			          style={{listStyle: "none"}} key={"select_automezzi"}>
				<select name="automezzi" value={this.props.camion} id={this.props.id} onChange={this.props.onChange}>
					<option value="">SCEGLI UNO DEI SEGUENTI</option>
					{self.state.automezzi.map((automezzo) => (
						<option key={automezzo.id}
						        value={automezzo.id}>{automezzo.doc.descrizione}</option>
					))}
				</select>
			</ListItem>
		)
	}
}

export class IWBarcodeReader extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			barcodes: [],
			tot_barcodes: 0,
			bins_fuoricircuito: [],
			tot_bins_fuoricircuito: 0,
			barcode_pesate: [],
			tot_barcode_pesate: 0
		}
		this.handleBarcode = this.handleBarcode.bind(this);
		this.handleBarcodeInput = this.handleBarcodeInput.bind(this);
		this.handlePaste = this.handlePaste.bind(this);
	}

	componentDidMount() {
		const self = this;
		document.addEventListener('paste', self.handlePaste);
		//localStorage.setItem("is_bolletta","1");
	}

	handlePaste(e) {
		const self = this;
		if (e.target.name !== "barcode") {
			let clipboardData = e.clipboardData || window.clipboardData;
			let pastedData = clipboardData.getData('Text');
			self.handleBarcode(pastedData);
		}
	}

	handleBarcodeInput(event) {
		const self = this;
		const target = event.target;
		const barcode = target.value;
		self.handleBarcode(barcode);
	}

	handleBarcode(barcode) {
		const self = this;
		const app = self.$f7;
		self.setState({
			barcode: barcode
		});

		let barcodes = self.state.barcodes;
		let barcode_pesate = self.state.barcode_pesate;


		if (!barcodes.includes(barcode)) {

			//barcode SCONTRINO PESATA
			if (barcode.length === 13) {
				//console.log("SCONTRINO");
				//console.log(self.state);
				//console.log("FINE SCONTRINO");
				barcode_pesate.push(barcode)
				self.setState({barcode_pesate: barcode_pesate, tot_barcode_pesate: barcode_pesate.length});
			} else {

				self.giacenzaBins(barcode);
				//console.log("SPARO BARCODE");
				//console.log(self.state);
				//console.log("SPARO BARCODE FINE");

				barcodes.push(barcode)
				self.setState({barcodes: barcodes, tot_barcodes: barcodes.length});
				/*const is_bolletta = localStorage.getItem("is_bolletta");
            console.log("IS_BOLLETTA "+is_bolletta);
            if (is_bolletta === "0") {
                console.log("IS_BOLLETTA DEVE ESSERE 0: "+is_bolletta);
                barcodes.pop();
                self.setState({barcodes: barcodes, tot_barcodes: barcodes.length});
            }*/
			}
		} else {
			app.methods.binsDuplicate();
		}
		//localStorage.setItem("is_bolletta", "1");

		if (typeof self.props.barcodeHandler === "function")
			self.props.barcodeHandler(barcodes,barcode_pesate);
	}

	giacenzaBins(bin){
		const self = this;
		const app = self.$f7;
		self.setState({
		}, function () {
			let bins_fuoricircuito = self.state.bins_fuoricircuito;
				return IWAjax({
					app: self.$f7,
					route: 'getGiacenzaMagazzino',
					data: {
						lottobin: bin
					},
					showPreloader: false,
					success: function (res) {
						console.log(res);
						if (res.data.bins_fuoricircuito) {
							//app.methods.binsFuoriCircuito()
							bins_fuoricircuito.push(bin);
							self.setState({bins_fuoricircuito: bins_fuoricircuito, tot_bins_fuoricircuito: bins_fuoricircuito.length});
						} else {
							self.setState({bins_fuoricircuito: bins_fuoricircuito});
						}
					},
					error: function (error) {
						console.log(error);
						self.setState({bins_fuoricircuito: bins_fuoricircuito});
					}
				});

		});
	}

	componentWillUnmount() {
		const self = this;
		document.removeEventListener('paste', self.handlePaste);
	}

	resetBinsList() {
		const self = this;
		const app = self.$f7;

		app.dialog.confirm('Vuoi Cancellare La Lista Bins?', function () {
			self.setState({barcodes: [], tot_barcodes: 0, bins_fuoricircuito: [], tot_bins_fuoricircuito: 0}, function () {
				self.props.barcodeHandler([]);
				app.dialog.close("#barcodes_list-popup",1);
			});
		});
	}
	deleteSelectedBin(index){
		const self = this;
		const app = self.$f7;

		app.dialog.create({
			title: "Bins App",
			text: 'Vuoi rimuovere il Bin con lotto ' + self.state.barcodes[index] + '?',
			cssClass: "iw-dialog",
			buttons: [
				{
					text: 'Annulla',
				},
				{
					text: 'Ok',
				}
			],
			onClick: function (dialog, ind) {
				if (ind === 1) {
					let newbins = self.state.barcodes;
					let newbins_fuoricircuito = self.state.bins_fuoricircuito;
					if (newbins_fuoricircuito.includes(self.state.barcodes[index])){
						let index_fuoricircuito = newbins_fuoricircuito.indexOf(self.state.barcodes[index]);
						newbins_fuoricircuito.splice(index_fuoricircuito, 1);
						self.setState({bins_fuoricircuito: newbins_fuoricircuito, tot_bins_fuoricircuito: newbins_fuoricircuito.length - 1});
					}
					newbins.splice(index, 1);
					self.setState({barcodes: newbins, tot_barcodes: newbins.length - 1});
				}
			}
		}).open();
	}

	render() {
		const self = this;
		const app = self.$f7;
		return (
			<ListGroup>
				<ListItem className={app.methods.isAndroidWebView() ? "display-none" : ""}>
					<Input label="Barcode" placeholder="Barcode" type="text" id="barcode"
					       name="barcode" onChange={self.handleBarcodeInput} barcodeHandler={self.props.barcodeHandler}
					       clearButton/>
				</ListItem>
				<ListItem title="Totale Bins" badge={self.state.barcodes.length}
				          popupOpen="#barcodes_list-popup"
				          link={"#"}/>

				<Popup id={"barcodes_list-popup"}>
					<Page>
						<Navbar
							title={"Riepilogo Bins"}>
							<NavRight>
								<Link onClick={self.resetBinsList.bind(self)}>Reset</Link>
								<Link popupClose>Chiudi</Link>
							</NavRight>
						</Navbar>
						<BlockTitle strong>{"Riepilogo Bins " + self.props.magazzinoSorgente.codice + " => " + self.props.magazzinoDestinazione.codice  + " - Numero BINS: " +self.state.barcodes.length}</BlockTitle>

						<List>
							{self.state.barcodes.map((barcode, index) => (
								<ListItem title={barcode} key={index}>
									<Button onClick={self.deleteSelectedBin.bind(self,index)}>
										<Icon material={"clear"} color={"red"}/>
									</Button>
								</ListItem>
							))}
						</List>
					</Page>
				</Popup>


				<ListItem title="Bins Fuori Circuito" badge={self.state.bins_fuoricircuito.length}
						  popupOpen="#bins_fuoricircuito_list-popup"
						  className={(self.state.bins_fuoricircuito.length > 0) ? "bg-color-yellow" : ""}
						  link={"#"}/>

				<Popup id={"bins_fuoricircuito_list-popup"}>
					<Page>
						<Navbar
							title={"Riepilogo Bins Fuori Circuito"}>
							<NavRight>

								<Link popupClose>Chiudi</Link>
							</NavRight>
						</Navbar>
						<BlockTitle strong>{"Riepilogo Bins Fuori Circuito " + self.props.magazzinoSorgente.codice + " => " + self.props.magazzinoDestinazione.codice  + " - Numero BINS: " +self.state.bins_fuoricircuito.length}</BlockTitle>

						<List>
							{self.state.bins_fuoricircuito.map((bins_fuoricircuito, index) => (
								<ListItem title={bins_fuoricircuito} key={index}>

								</ListItem>
							))}
						</List>
					</Page>
				</Popup>

				<Popup id={"barcode_pesate_list-popup"}>
					<Page>
						<Navbar
							title={"Riepilogo Numero Scontrini Pesate"}>
							<NavRight>
								<Link popupClose>Chiudi</Link>
							</NavRight>
						</Navbar>
						<BlockTitle strong>{"Riepilogo Numero Pesate " + self.props.magazzinoSorgente.codice + " => " + self.props.magazzinoDestinazione.codice  + " - Numero Scontrini Pesate: " +self.state.barcode_pesate.length}</BlockTitle>

						<List>
							{self.state.barcode_pesate.map((barcode, index) => (
								<ListItem title={barcode} key={index}>
								</ListItem>
							))}
						</List>
					</Page>
				</Popup>

			</ListGroup>
		)
	}
}

export class ListItemSplit extends React.Component {
	render() {
		const self = this;
		const children = self.props.children || [];

		return (
			<div className="item-inner item-cell no-padding no-margin">
				<div className="item-row">
					{children.map((child, i) => (
						<div className="item-cell no-margin" key={self.props.name + "_" + i}>
							{child}
						</div>
					))}
				</div>
			</div>
		);
	}
}