function Config() {

    this.codicecliente = "olma";
    if(process.env.NODE_ENV === "development"){
        this.iride_url = "http://irideweb";
    }
    else{
        this.iride_url = "https://olma.irideglobalservice.it";
    }

    this.tech_password = "olma1267x"
    this.api_url = this.iride_url + "/api/webapps/IWRestOlmaApp/";
    this.client_secret = "LXMHTKNFJZIUOGAE";
}

export default Config;