import DbEntity from "./DbEntity";
import Trasporti from "./Trasporti";

export default class Bollette extends DbEntity {
	constructor() {
		super("bollette", [['_id', 'is_synced'], ['timestamp', 'idanagrafica']]);

		/*this.db.createIndex({
			index: {
				fields: ['_id', 'is_synced']
			}
		}).then(function (result) {
			// handle result
			console.log("INDEX", result);
		}).catch(function (err) {
			console.log("INDEX", err);
		});*/
	}

	async getToSync(){
		try {
			const resp =  await this.db.find({
				selector: {
					_id: {"$gte": null},
					is_synced: 0
				},
				sort: ['_id']
			});

			let r = {docs: []};

			r.docs = await Promise.all(resp.docs.map(async (ent) => {
				try {
					const {idtrasporto} = ent;
					const trasp = await new Trasporti();
					ent.trasporto = await (trasp.getById(idtrasporto));

					return ent;
				} catch (err) {
					throw err;
				}
			}));

			return r;


		} catch (err) {
			console.log(err);
		}
	}

	async getRegistro(params){
		const start = params["start"];
		const end = params["end"];
		const idsocio = params["idsocio"];

		let selector = {
			timestamp: {$gte: start, $lte: end},
			idanagrafica: idsocio ? {$eq: idsocio.toString()} : {$gte: null}
		}

		try {
			const resp = await this.db.find({selector: selector, sort: ['timestamp']});
			return resp;
		} catch (e) {
			console.log(e);
		}
	}

}