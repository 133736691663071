import React from "react";
import {Navbar, NavLeft, NavTitle, Page, List, ListItem, ListGroup} from "framework7-react";
import iride_logo from "../assets/iride_logo.png";
import Mezzi from "../entities/Mezzi";
import IWAjax from "../utils/IWAjax";

export default class Giacenza extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			camion: localStorage.getItem("camion") || "",
			mezzo: {codice: ""},
			user: JSON.parse(localStorage.getItem("user")),
			giacenza: [{lotto: "", q: 0}],
			qtot: 0
		}
	}

	async componentDidMount() {
		const self = this;
		const app = self.$f7;

		const m = await new Mezzi();
		m.getById(self.state.camion).then(r => {
			this.setState({"mezzo": r}, () => {
				IWAjax({
					app: app,
					route: 'getGiacenzaMagazzino',
					data: {idmagazzino: r._id},
					showPreloader: false,
					success: function (res) {
						if(res.data){
							let qtot = 0
							for(let lotto of res.data)
								qtot += parseInt(lotto.q);

							self.setState({giacenza: res.data, qtot: qtot})
						}
					},
					error: function (err) {
						console.log(err)
					}
				});
			});
		});

	}

	render() {
		const self = this;

		return (
			<Page name={"Giacenza"}>
				<Navbar>
					<NavLeft backLink="Back" backLinkForce backLinkUrl={"/home/"}/>
					<NavTitle><img src={iride_logo} alt='logo'
					               height="30rem"/>{"Giacenza Camion " + self.state.mezzo.codice}</NavTitle>
				</Navbar>
				<List>
					<ListItem title="Camion" after={self.state.mezzo.codice + " - " + self.state.mezzo.descrizione}/>
					<ListItem title={"Autista"} after={self.state.user.username}/>
					<ListItem title={"N. Bins In Giacenza"} after={self.state.qtot}/>
				</List>

				<List style={{marginBottom: 0}}>
					<ListGroup>
						<ListItem title="Bins" groupTitle/>
					</ListGroup>
				</List>

				<List style={{maxHeight: "20rem", overflow: "auto", marginTop: 0}}>

					{self.state.qtot > 0 ? self.state.giacenza.map((lotto, i) => (
							<ListItem key={"bins_" + i} title={(i + 1) + ") " + lotto.lotto} badge={lotto.q}/>
					)) : ""}
				</List>

			</Page>
		)
	}
}
