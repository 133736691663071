import DbEntity from "./DbEntity";
import PouchDB from 'pouchdb';

import Config from "../Config";

export default class Movimentazioni extends DbEntity {
	constructor() {
		super("movimentazioni", [['id_trasporto']]);

		/*this.db.createIndex({
			index: {
				fields: ['id_trasporto']
			}
		}).then(function (result) {
			// handle result
			console.log("INDEX", result);
		}).catch(function (err) {
			console.log("INDEX", err);
		});*/
	}

	async getByTrasporto(idtrasporto){
		try {
			const resp = await this.db.find({
				selector: {
					id_trasporto: {$eq: idtrasporto}
				}
			});
			return resp;
		} catch (e) {
			console.log(e);
		}
	}

	replicate(){
		const config = new Config();
		const rep = PouchDB.replicate('movimentazioni', config.iride_url + ':5984/movimentazioni', {
			live: true,
			retry: true
		}).on('change', function (info) {
			// handle change
			console.log("REPL change", info);
		}).on('paused', function (err) {
			// replication paused (e.g. replication up to date, user went offline)
			console.log("REPL paused", err);
		}).on('active', function () {
			// replicate resumed (e.g. new changes replicating, user went back online)
			console.log("REPL active");
		}).on('denied', function (err) {
			// a document failed to replicate (e.g. due to permissions)
			console.log("REPL paused", err);
		}).on('complete', function (info) {
			// handle complete
			console.log("REPL complete", info);
		}).on('error', function (err) {
			// handle error
			console.log("REPL error", err);
		});

		rep.cancel();
	}

	async replicate2(){
		const config = new Config();
		try {
			return await this.db.replicate.to(config.iride_url + ':5984/movimentazioni');
		} catch (err) {
			console.log(err);
		}
	}
}