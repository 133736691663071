import DbEntity from "./DbEntity";
import Config from "../Config";

export default class Users extends DbEntity {
	constructor() {
		super("users", [['username', 'password']]);

		/*this.db.createIndex({
			index: {
				fields: ['username', 'password']
			}
		}).then(function (result) {
			// handle result
			// console.log("INDEX", result);
		}).catch(function (err) {
			console.log("INDEX", err);
		});*/
	}

	populate(callback){
		const self = this;
		self.db.get('tech').then(function (doc) {
			//console.log(doc);
		}).catch(function (err) {
			//console.log(err);
			const config = new Config();
			self.db.put({
				_id: 'tech',
				codice: 'tech',
				descrizione: 'tech',
				is_trasportatore: 1,
				username: 'tech',
				password: config.tech_password.toLowerCase()
			}).then(function (response) {
				// handle response
				//console.log(response);
				if(typeof callback === "function"){
					callback(response)
				}
			}).catch(function (err) {
				console.log(err);
				if(typeof callback === "function"){
					callback(err)
				}
			});
		});
	}

	async findFromUserAndPassword(username, password) {

		try {
			username = username.toLowerCase();
			password = password.toLowerCase();
			return await this.db.find({
				selector: {username: username, password: password},
			});
		} catch (err) {
			console.log(err);
		}
	}

	async getById(id) {
		if(id === 1)
			id = 'tech'

		return await super.getById(id);
	}

}
