import React from 'react';
import {
	Page,
	Input,
	Block,
	BlockTitle,
	BlockHeader,
	List,
	ListItem,
	ListButton, NavLeft, Link, NavTitle, Navbar, NavRight,
} from 'framework7-react';
import axios from "axios";
import qs from "qs";
import Config from "./Config";
import Users from "./entities/Users";
import olma_logo from './assets/olma_logo.jpg'
import iride_logo from "./assets/iride_logo.png";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: ""
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	async componentDidMount() {
		const self = this;
		const app = self.$f7;

		app.methods.sendCoordinates();

		const setup_complete = localStorage.getItem("app_setup_completed");
		if(!parseFloat(setup_complete)){
			self.$f7.methods.bgSync(0, () => {
				localStorage.setItem("app_setup_completed", "1");
				self.$f7.welcomescreen.close();
			})
		} else
			self.$f7.welcomescreen.close();

		const users = await new Users();
		users.populate(function (res) {
			//console.log(res);
		});

		window.appLogout = function () {
			if (navigator.userAgent.includes('wv'))
				window.location.href = "http://killme";
		};

		this.handleKeyPress = this.handleKeyPress.bind(this);
		document.addEventListener("keypress", this.handleKeyPress, false);
	}

	async localLogin(){
		const self = this;
		const app = self.$f7;
		const formData1 = app.form.convertToData("#form-login");
		const users = await new Users();
		const user = await users.findFromUserAndPassword(formData1.username, formData1.password);
		const router = self.$f7router;

		if(user.docs.length > 0){
			localStorage.setItem("username_palmare", self.state.username);
			localStorage.setItem("user", JSON.stringify(user.docs[0]));
			router.navigate("/home/");
			return true;
		}

		return false;
	}


	remoteLogin(){
		const config = new Config();
		const self = this;
		const app = self.$f7;
		const formData1 = app.form.convertToData("#form-login");

		formData1["codicecliente"] = config.codicecliente;
		const router = self.$f7router;
		app.preloader.show();
		axios({
			method: 'POST',
			headers: {'content-type': 'application/x-www-form-urlencoded'},
			data: qs.stringify(formData1),
			url: config.iride_url + "/ajax.php?JSON=1&object=IWUser&tipoview=login"
		}).then(function (res) {
			app.preloader.hide();
			if (res.data.ret !== "true") {
				app.dialog.alert("Credenziali Errate");
				return false;
			}
			localStorage.setItem("username_palmare", self.state.username);
			//localStorage.setItem("user_idmagazzino", res.data.idmagazzino);
			router.navigate("/home/");
		})
	}

	async handleClick() {
		const self = this;
		const app = this.$f7;
		const local_login = await self.localLogin();
		//console.log("SUCCESS", local_login);
		if(!local_login)
			app.dialog.alert("Credenziali Errate");
			//self.remoteLogin();
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	async handleKeyPress(event) {
		const self = this;
		if (event.which === 13 && self.state.username !== "" && self.state.password !== "") {
			await self.handleClick();
		}
	}

	logoutConfirm(){
		const self = this;
		const app = self.$f7;

		app.dialog.create({
			title: "Bins App",
			text: 'Effettuare la sincronizzazione manuale?',
			cssClass: "iw-dialog",
			buttons: [
				{
					text: 'Annulla',
				},
				{
					text: 'Ok',
				}
			],
			onClick: function (dialog, ind) {
				if (ind === 1) {
					self.$f7.methods.bgSync(0);
				}
			}
		}).open();
	}

	render() {
		const self = this;
		return (
			<Page name="login" id="login">
				<Navbar>
					<NavLeft>
						<img src={iride_logo} alt='logo' height="30rem"/>
					</NavLeft>
					<NavTitle>Gestione Bins - Mobile App</NavTitle>
					<NavRight>
						<Link onClick={() => self.logoutConfirm()} iconOnly iconMaterial="sync" link="#"/>
					</NavRight>
				</Navbar>
				<Block>
					<BlockHeader className="text-align-center"><img src={olma_logo} alt='logo'/></BlockHeader>
					<BlockTitle>Area Riservata</BlockTitle>
					<form id="form-login">
						<List>
							<ListItem>
								<Input label="Username" name="username" id="usename" type="text" placeholder="Username"
								       onChange={this.handleInputChange} clearButton value={this.state.username}/>
							</ListItem>
							<ListItem>
								<Input label="Password" name="password" id="password" type="password"
								       placeholder="Password" onChange={this.handleInputChange} clearButton
								       value={this.state.password}/>
							</ListItem>
						</List>
						<List>
							<ListButton type="submit" className="button button-fill" color="white"
							            onClick={this.handleClick.bind(this)}>Login</ListButton>
						</List>
					</form>
				</Block>
			</Page>
		);
	}
}

export default Login;
