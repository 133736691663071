import React from 'react';
import {
	Page,
	List,
	NavLeft,
	NavRight,
	Link,
	ListItem,
	ListGroup,
	BlockTitle, Toggle,
} from 'framework7-react';
import {IWBarcodeReader, IWHeader, IWLocalAutocomplete, SelectAutomezzi} from "../IWComponents";
import Deposito from "../entities/Deposito"
import Trasporti from "../entities/Trasporti";
import Mezzi from "../entities/Mezzi";
import IWAjax from "../utils/IWAjax";
import Soci from "../entities/Soci";
import Dom7 from "dom7";

export default class MovimentazioneBins extends React.Component {
	opt;

	constructor(props) {
		super(props);

		const opt = this.props.opt;
		let sorgente = new Deposito();
		let destinazione = {};

		if (opt === "s2o" || opt === "c2o" || opt === "m2o") {
			destinazione = sorgente;
			sorgente = {};
		}

		if (opt === "c2s") {
			sorgente = {}
			destinazione = {}
		}

		if (opt === "o2o"){
			destinazione = sorgente;
		}

		this.state = {
			magazzino_destinazione: destinazione,
			magazzino_sorgente: sorgente,
			movimentazioni: [],
			tot_bins: 0,
			bins: [],
			idsocio: undefined,
			bins_giacenza: 0,
			max_bins: 0,
			bolletta_bins: 0,
			is_vuoti_camion: false,
			is_vuoti_socio: false,
			inventario: 0,
			barcode_pesate: [],
			idmezzo: 0
		};

		this.socioChanged = this.socioChanged.bind(this)
		this.automezzoChanged = this.automezzoChanged.bind(this);
	}

	async componentDidMount() {
		const self = this;
		global.app = self.$f7;
		localStorage.setItem("is_bolletta_bin", "0");
		self.setState({inventario : 0});
		document.addEventListener('paste', self.handlePaste);

		if (self.props.opt === "s2m") {
			const camion = localStorage.getItem("camion")
			if (camion !== "") {
				const mezzi = await new Mezzi();
				mezzi.getById(camion).then(r => {
					self.setState({magazzino_destinazione: r, magazzino_sorgente: {}});
				});
			}
		}

		if (self.props.opt === "c2s") {
			const camion = localStorage.getItem("camion")
			if (camion !== "") {
				const mezzi = await new Mezzi();
				mezzi.getById(camion).then(r => {
					self.setState({magazzino_sorgente: r});
				});
			}
		}
		if (self.props.opt === "o2o") {
			self.setState({inventario : 1});
		}
		if (self.props.opt === "m2o") {
			self.$f7.smartSelect.get('.smart-select').setValue(0);
		}
	}

	socioChanged(event) {
		const self = this;
		const target = event.target;
		const name = target.name;
		const value = target.value;

		self.setState({
			[name]: value
		}, function () {
			if (self.props.opt.endsWith("2s")) {
				return IWAjax({
					app: self.$f7,
					route: 'getGiacenzaMagazzinoSocio',
					data: {
						idmagazzino: self.state.magazzino_destinazione._id,
						idanagrafica: self.state.magazzino_destinazione.idanagrafica
					},
					showPreloader: false,
					success: function (res) {
						//console.log(res);
						if (res.data) {
							let max_bins = res.data.max_bins;
							let nbins = 0;
							for (let giac of res.data.rows) {
								nbins += parseFloat(giac.q);
							}
							self.setState({max_bins: max_bins})
							self.setState({bins_giacenza: nbins});
						} else {
							self.setState({bins_giacenza: "N/A"});
						}
					},
					error: function (error) {
						console.log(error);
						self.setState({bins_giacenza: "N/A"});
					}
				});
			}
		});
		//self.giacenzaBollettaNonLavorata();
	}




	giacenzaBollettaNonLavorata() {
		const self = this;
		if (self.state.idsocio && self.state.magazzino_sorgente) {
			self.setState({
			}, function () {
				if (self.props.opt.endsWith("m2o")) {
					return IWAjax({
						app: self.$f7,
						route: 'getBollettaNonLavorata',
						data: {
							trasportatore: self.state.magazzino_sorgente._id,
							socio: self.state.idsocio._id
						},
						showPreloader: false,
						success: function (res) {
							console.log(res);
							if (res.data) {
								self.setState({bolletta_bins: res.data.bolletta_bins});
							} else {
								self.setState({bolletta_bins: "N/A"});
							}
						},
						error: function (error) {
							console.log(error);
							self.setState({bolletta_bins: "N/A"});
						}
					});
				}
			});
		}
	}

	getBollettaDaBin($cassone) {
		const $$ = Dom7;
		global.$$ = $$;
		const self = this;
		const app = self.$f7;
		global.app = app;

		if (localStorage.getItem("is_bolletta_bin") === "1"){
			return;
		}

		return IWAjax({
			app: self.$f7,
			route: 'getBollettaDaBin',
			data: {
				bin: $cassone
			},
			showPreloader: false,
			success: async function (res) {
				console.log("Bolletta da camion RES: ");
				console.log(res);
				if (res.data) {

					if (res.data.trasportatore !== null){
						let smartSelect = app.smartSelect.get('.smart-select');
						smartSelect.setValue(res.data.trasportatore);

						self.setState({idmezzo: res.data.trasportatore}, async ()=>{
							await self.automezzoChanged();
							$$('#automezzi-smartselect option[value="' + res.data.trasportatore + '"]').change();
						});

						const soci = await new Soci();
						soci.getById(res.data.socio).then(r => {
							self.setState({idsocio: r})
							$$('#idsocio input').val(r.full_desc);
						});

						self.setState({bolletta_bins: res.data.bolletta_bins});
						localStorage.setItem("is_bolletta_bin", "1");
					}
					else{
						if (localStorage.getItem("is_bolletta_bin") === "0") {
							app.methods.bollettaAssente();
						}
					}

				} else {
					self.setState({trasportatore: "", socio: "", bolletta_bins: ""});
				}
			},
			error: function (error) {
				console.log(error);
				self.setState({trasportatore: "", socio: "", bolletta_bins: ""});
			}
		});
	}

	async saveMovimentazione() {

		const trasp = await new Trasporti();
		const self = this;
		const app = self.$f7;

		if(self.props.opt === "c2s" || self.props.opt === "s2m"){
			let coordinates = JSON.parse(app.methods.androidCoordinates());
			self.setState({latitudine: coordinates.lat});
			self.setState({longitudine: coordinates.lon});
			console.log(coordinates);
		}

		app.dialog.create({
			title: "Salvataggio Operazione",
			text: 'Effettuare il salvataggio?',
			cssClass: "iw-dialog",
			buttons: [
				{
					text: 'Annulla',
				},
				{
					text: 'Ok',
				}
			],
			onClick: async function (dialog, ind) {
				if (ind === 1) {

					const resp = await trasp.saveTrasportiFromView(self);

					if (self.props.opt === "m2o") {
						if (localStorage.getItem("is_bolletta_bin") === "1" && self.state.is_vuoti_camion){
							resp.ok = false;
							resp.message = "Bolletta Presente in OLMA: Rimuovere flag a VUOTI DA CAMION";
						}
						if (!self.state.idsocio && !self.state.is_vuoti_camion){
							resp.ok = false;
							resp.message = "Selezionare flag VUOTI DA CAMION se i BINS sono VUOTI";
						}
					}

					if (self.props.opt === "s2o"){
						if (self.state.barcode_pesate.length === 0 && !self.state.is_vuoti_socio){
							resp.ok = false;
							resp.message = "Selezionare flag VUOTI DA SOCIO se i BINS sono VUOTI oppure sparare il barcode della PESATA MULETTO";
						}
						if (self.state.barcode_pesate.length > 0 && self.state.is_vuoti_socio){
							resp.ok = false;
							resp.message = "Rimuovere flag VUOTI DA SOCIO";
						}
					}

					const success = resp.ok;
					app.methods.showIWResponse(resp);

					if (success) {
						app.methods.bgSync(2);
						if (self.props.opt === "o2o"){
							app.views.main.router.refreshPage();
						} else{
							self.$f7router.navigate("/home/");
						}
					}
				}
			}
		}).open();
	}

	async automezzoChanged() {
		const self = this;
		const app = self.$f7;
		let smartSelect = app.smartSelect.get('.smart-select');
		const idmezzo = smartSelect.getValue();
		console.log(idmezzo);


		if (self.props.opt === "m2o") {
			const magazzini = await new Mezzi();
			const sorgente = await magazzini.getById(self.state.idmezzo === 0 ? idmezzo : self.state.idmezzo);
			//console.log(sorgente);
			self.setState({magazzino_sorgente: sorgente});
			//self.giacenzaBollettaNonLavorata();
		}

		if (self.props.opt === "o2m") {
			const destinazione = await new Mezzi().getById(idmezzo);
			self.setState({magazzino_destinazione: destinazione});
		}
	}

	vuotiCheck(e) {
		const $$ = Dom7;
		global.$$ = $$;
		const self = this;
		self.setState({idsocio: undefined})
		$$('#idsocio input').val("");
		self.setState({is_vuoti_camion: e, is_vuoti_socio: e});
	}

	barcodeHandler(ret, ret1) {
		const self = this;
		if (!ret)
			ret = [];
		self.setState({bins: ret, tot_bins: ret.length, barcode_pesate: ret1});

		if (self.props.opt === "m2o") {
			self.getBollettaDaBin(ret[0]);
		}
	}

	resetPage() {
		const self = this;
		const app = self.$f7;

		app.dialog.create({
			title: "Bins App",
			text: 'Ricaricare La Pagina? Tutte Le Modifiche Non Salvate Andranno Perse',
			cssClass: "iw-dialog",
			buttons: [
				{
					text: 'Annulla',
				},
				{
					text: 'Ok',
				}
			],
			onClick: function (dialog, index) {
				if(index === 1){
					app.views.main.router.refreshPage();
				}
			}
		}).open();
	}

	render() {
		const self = this;

		let title = "Consegna Bins A Socio";
		let dest;
		let dest_id = (self.props.opt.startsWith("o2") && self.props.opt !== "o2o")  ? "magazzino_destinazione" : "magazzino_sorgente";
		if (self.props.opt === "c2s") {
			dest_id = "magazzino_destinazione"
		}

		if (self.props.opt === "o2m") {
			title = "Consegna Bins A Camion";
			dest = <SelectAutomezzi
				id={'automezzi'}
				camion={self.state.magazzino_destinazione._id}
				onChange={self.automezzoChanged}
			/>
		} else {
			if (self.props.opt === "o2o"){
				title = "Inventario";
			} else {
				//let tsocio = self.state.magazzino_sorgente.is_bio !== undefined ? self.state.magazzino_sorgente.is_bio : self.state.magazzino_destinazione.is_bio;
				dest = <IWLocalAutocomplete
					title={"Socio"}
					id={dest_id}
					type={"Soci"}
					onChange={self.socioChanged}
					autofocus={false}
				/>
				if (self.props.opt.endsWith("2s")) {
					dest = <ListGroup>
						{dest}
						<ListItem title="Bins Già Consegnati" badge={self.state.bins_giacenza.toString()}
								  onChange={self.giacenzaBollettaNonLavorata()}
								  className={(self.state.bins_giacenza + self.state.tot_bins) > self.state.max_bins ? "bg-color-red" : ""}/>
						<ListItem title="Bins Assegnabili" badge={self.state.max_bins.toString()}
								  className={(self.state.bins_giacenza + self.state.tot_bins) > self.state.max_bins ? "bg-color-red" : ""}/>
					</ListGroup>
				}
			}
		}
		if (self.props.opt === "s2o") {
			title = "Ritiro Bins Da Socio";
		}

		if (self.props.opt === "m2o") {
			title = "Ritiro Bins Da Camion";
			dest = <List className="no-margin no-padding">
				<SelectAutomezzi
					id={'automezzi-smartselect'}
					camion={self.state.magazzino_sorgente !== undefined ? self.state.magazzino_sorgente._id :  ""}
					onChange={self.automezzoChanged}
				/>
				<IWLocalAutocomplete
					title={self.state.idsocio !== undefined ? self.state.idsocio.is_bio !== "0" ? "Socio Bio" : "Socio": "Socio"}
					id="idsocio"
					type={"Soci"}
					onChange={self.socioChanged}
					autofocus={false}
				/>
				<ListItem
					title="Bins in Bolletta"
					badge={self.state.bolletta_bins.toString()}
					className={(self.state.bolletta_bins === self.state.tot_bins && self.state.bolletta_bins !== 0) ? "bg-color-green" : "bg-color-red"}
				/>
				<ListItem>
					<span>Bins Vuoti da Camion</span>
					<Toggle
						name={"is_vuoti_camion"}
						onToggleChange={e => self.vuotiCheck(e)}
						disabled={localStorage.getItem("is_bolletta_bin") === "1"}
					/>
				</ListItem>
			</List>
		}

		if (self.props.opt === "s2o") {
			dest = <ListGroup>
				{dest}
				<ListItem>
				<span>Bins Vuoti da Socio</span>
					<Toggle
						name={"is_vuoti_socio"}
						onToggleChange={e => self.vuotiCheck(e)}
					/>
				</ListItem>
				<ListItem title="Numero Pesate" badge={self.state.barcode_pesate.length}
							 popupOpen="#barcode_pesate_list-popup"
							 link={"#"}/>
				</ListGroup>
		}

		if (self.props.opt === "s2m") {
			title = "Ritiro Bins Vuoti Da Socio";
		}

		return (
			<Page name="home">
				<IWHeader title={title}>
					<NavLeft backLink="Back"/>
					<NavRight>
						<Link onClick={self.resetPage.bind(self)}>Reset</Link>
						<Link onClick={self.saveMovimentazione.bind(self)}>Salva</Link>
					</NavRight>
				</IWHeader>
				<BlockTitle strong>{title}</BlockTitle>
				<List>
					{dest}
					<IWBarcodeReader
						barcodeHandler={self.barcodeHandler.bind(self)}
						magazzinoSorgente={self.state.magazzino_sorgente}
						magazzinoDestinazione={self.state.magazzino_destinazione}
					/>
				</List>
			</Page>
		);
	}
}