import React from 'react';
import {App, View} from 'framework7-react';
import 'framework7-icons';
import Login from './Login.js';
import Home from "./Home.js";
import Sync from "./Sync.js";
import MovimentazioneBins from "./magazzino/MovimentazioneBins"
import Bolletta from "./magazzino/Bolletta";
import Synchronization from "./utils/Synchronization";
import iride_logo from "./assets/iride_logo.png";
import Config from "./Config";
import Framework7 from "framework7";
import Registro from "./Registro";
import Giacenza from "./magazzino/Giacenza";

import packageJson from '../package.json';
import CacheBuster from "./utils/CacheBuster";
import IWAjax from "./utils/IWAjax";
import moment from "moment";


global.appVersion = packageJson.version;
global.F7app = null;

const splashscreen = [
    {
        id: 'splash',
        title: 'Gestione Bins',
        //picture: '<div class="tutorialicon">♥</div>',
        text: 'Attendere Configurazione In Corso'
    }
];

const f7params = {
    theme: 'auto',
    name: "IrideWEB Mobile",
    id: 'com.olmabinsapp.iride',
    toast_sync: {},
    welcomescreen: {
        slides: splashscreen,
        options: {
            'bgcolor': '#0da6ec',
            'fontcolor': '#fff'
        },
    },
    view: {
        pushState: true,
        pushStateSeparator: '',
        pushStateOnLoad: false
    },
    routes: [
        {
            path: '/login/',
            component: Login,
            on: {
                pageAfterIn(e, page) {
                    if (page.router.history.length > 0)
                        page.router.clearPreviousHistory();
                },
            }
        },
        {
            path: '/home/',
            component: Home,
            on: {
                pageAfterIn(e, page) {
                    //if (page.router.history.includes("/login/"))
                    if (page.router.history.length > 1 || page.router.history[0] !== "/home/")
                        page.router.clearPreviousHistory();//.clearPreviousHistory();
                },
            }
        },
        {
            path: '/sync/',
            component: Sync,
        },
        {
            path: "/magazzino/movimentazione/:opt",
            component: MovimentazioneBins
        },
        {
            path: "/magazzino/bolletta/:opt",
            component: Bolletta
        },
        {
            path: '/registro/:opt',
            component: Registro,
        },
        {
            path: '/giacenza/',
            component: Giacenza,
        }

    ],
    methods: {
        fullSyncLogout: function () {
            // eslint-disable-next-line no-undef
            const app = F7app;
            app.props.params.methods.bgSync(0, function (r) {
                localStorage.removeItem("username");
                localStorage.removeItem("username_palmare");
                localStorage.removeItem("user");
                localStorage.removeItem("camion");
                window.location.href = window.location.origin;
            });
        },
        hideSoftKeyboard: function () {
            setTimeout(function () {
                try {
                    /*eslint-disable no-undef*/
                    Android.hideKeyboard();
                    /*eslint-enable no-undef*/
                } catch (e) {
                    return false;
                }
            }, 500);
        },
        androidAppVersion: function () {
            try {
                /*eslint-disable no-undef*/
                return Android.appVersion();
                /*eslint-enable no-undef*/
            } catch (e) {
                return false;
            }
        },
        androidReadIMEI: function () {
            try {
                /*eslint-disable no-undef*/
                return Android.readIMEI();
                /*eslint-enable no-undef*/
            } catch (e) {
                return false;
            }
        },
        androidCoordinates: function () {
            try {
                /*eslint-disable no-undef*/
                return Android.gps();
                /*eslint-enable no-undef*/
            } catch (e) {
                return false;
            }
        },
        isAndroidWebView() {
            return navigator.userAgent.includes('wv');
        },
        showNotification(title, body) {

            /*"//": "Visual Options",
                "body": "<String>",
                "icon": "<URL String>",
                "image": "<URL String>",
                "badge": "<URL String>",
                "vibrate": "<Array of Integers>",
                "sound": "<URL String>",
                "dir": "<String of 'auto' | 'ltr' | 'rtl'>",
                "//": "Behavioural Options",
                "tag": "<String>",
                "data": "<Anything>",
                "requireInteraction": "<boolean>",
                "renotify": "<Boolean>",
                "silent": "<Boolean>",
                "//": "Both Visual & Behavioural Options",
                "actions": "<Array of Strings>",
                "//": "Information Option. No visual affect.",
                "timestamp": "<Long>"*/

            const options = {
                body: body,
                icon: {iride_logo}
            }

            if (window.Notification && window.Notification.permission === "granted" && !(new Config().debug)) {
                navigator.serviceWorker.ready.then(registration => {
                    registration.showNotification(title, options);
                });
            } else {
                Framework7.instance.toast_sync.show();
            }
        },
        showIWResponse(resp) {
            const success = resp.ok;
            let title = '<p style="color:black;font-weight: bold;text-align:center;">IrideWeb - ATTENZIONE!';
            let msg = '<p style="color:red;font-size:18px;">ERRORE DURANTE IL SALVATAGGIO<br/>' + resp.message;

            if (success) {
                title = '<p style="color:black;font-weight: bold;text-align:center;">Irideweb';
                msg = '<p style="color:forestgreen;font-size:18px;">OPERAZIONE EFFETTUATA CON SUCCESSO';
            }

            this.dialog.create({
                title: title,
                text: msg,
                cssClass: "iw-dialog",
                buttons: [
                    {
                        text: 'Chiudi',
                    }
                ],
            }).open();
        },
        /*binsFuoriCircuito() {
            let interval;
            const toastBottom = Framework7.instance.toast.create({
                text: 'AVVISO: Bin fuori circuito!!!',
                closeTimeout: 2000,
                on: {
                    opened: function (toast) {
                        interval = setInterval(function () {
                            const el = toast.$el;
                            el.animate({'opacity': 0.2}, {duration: 2000}).animate({'opacity': 1}, {duration: 2000})
                        }, 5000)
                    },
                }
            });
            toastBottom.open();
        },*/
        binsDuplicate() {
            let interval;
            const toastBottom = Framework7.instance.toast.create({
                text: 'AVVISO: Bin già sparato!!!',
                closeTimeout: 2000,
                on: {
                    opened: function (toast) {
                        interval = setInterval(function () {
                            const el = toast.$el;
                            el.animate({'opacity': 0.2}, {duration: 2000}).animate({'opacity': 1}, {duration: 2000})
                        }, 5000)
                    },
                }
            });
            toastBottom.open();
        },
        bollettaAssente() {
            let interval;
            const toastBottom = Framework7.instance.toast.create({
                text: 'AVVISO: Bolletta non arrivata in OL.MA.!!!',
                closeTimeout: 2000,
                on: {
                    opened: function (toast) {
                        interval = setInterval(function () {
                            const el = toast.$el;
                            el.animate({'opacity': 0.2}, {duration: 4000}).animate({'opacity': 1}, {duration: 2000})
                        }, 2000)
                    },
                }
            });
            toastBottom.open();
        },
        bgSync(mode, callback) {
            let interval;
            let is_sync = localStorage.getItem("is_sync");
            console.log("in corso "+is_sync);
            if (is_sync === "1") return;

            try {
                const toast_sync = Framework7.instance.toast.create({
                    text: 'Sincronizzazione In Corso',
                    destroyOnClose: true,
                    on: {
                        opened: function (toast) {
                            console.log(toast.$el);
                            if (!toast.$el) return;
                            interval = setInterval(function () {
                                const el = toast.$el;
                                el.animate({'opacity': 0.2}, {duration: 1000}).animate({'opacity': 1}, {duration: 1000})
                            }, 1000)
                        },
                    }
                });
                toast_sync.open();

                localStorage.setItem("is_sync", "1");
                new Synchronization().syncStart(mode).then(r => {
                    try {
                        toast_sync.close();
                        toast_sync.$el.remove();
                    } catch (e) {
                        throw (e);
                    } finally {
                        toast_sync.close();
                        toast_sync.$el.remove();
						localStorage.setItem("is_sync", "0");
                    }
					clearInterval(interval);
                    if (typeof callback === "function")
                        callback(r);
                });
            } catch (e) {
                console.log(e);
            } finally {

            }
        },
        sendCoordinates() {
            //const user = JSON.parse(localStorage.getItem("user"));
            //if (!user || user.is_trasportatore !== "1")
            //    return false;
            // eslint-disable-next-line no-undef
            const app = F7app;
            const coordinates = JSON.parse(app.props.params.methods.androidCoordinates());

            const timestamp = moment().format("X");

            return IWAjax({
                app: app,
                route: "writeEntities",
                data: {
                    entity: "coordinate",
                    docs: {
                        lat: coordinates.lat, lon: coordinates.lon, imei: app.props.params.methods.androidReadIMEI(),
                        timestamp: timestamp
                    },
                },
                showPreloader: false,
                success: async function (res) {
                    //console.log(res);
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }
    }
};

const username = localStorage.getItem("username_palmare");

export default () => {
    const app = <App params={f7params}>
        <View main url={username ? "/home/" : "/login/"}/>
    </App>

    const full_app = <CacheBuster>
        {({loading, isLatestVersion, refreshCacheAndReload}) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
                // You can decide how and when you want to force reload
                refreshCacheAndReload();
            }

            return app;
        }}
    </CacheBuster>

    global.F7app = app;

    window.bgSync = app.props.params.methods.bgSync;
    window.showNotification = app.props.params.methods.showNotification;
    window.sendCoordinate = app.props.params.methods.sendCoordinates;

    return full_app;
};

